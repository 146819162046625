.section
{
  &.upload
    {
      width: 100%;
      .note
      {
        color: red;
        font-size: 14px;
        text-align: left;
        margin: 5px 0px 0px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
        &.optional
        {
          color: #3b88b9;
        }
      }
      .btnUpload
      {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: 20px 0px 0px;
        .info
        {
          color: #3b88b9;
          font-size: 14px;
          margin: 0px;
          padding: 5px 80px 5px 15px;
          border: 1px solid #3b88b9;
          border-radius: 7px;
          text-align: left;
          @media screen and (max-width: 767px) {
            width: calc(100% - 40px);
          }
        }
        input
        {
          display: none;
        }
        .btnContainer
        {
          display: block;
          width: 30px;  
          height: 100%;
          margin-left: 15px;
          @media screen and (max-width: 767px) {
            width: 25px;
            margin-left: 15px;
          }
          button
          {
            cursor: pointer;
            padding: 50%;
            background-color: #3b88b9;
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border: none;
            border-radius: 7px;
            outline: none;
            transform: translateY(-2px);
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            box-shadow: 0 5px 5px 0px #2b2b2b;
            // margin-bottom: 2px;
            &:hover
            {
              background-color: #4e98c7;
            }
            &:active
            {
              box-shadow: 0 2px #2b2b2b;
              transform: translateY(0px);
              transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
      }
    }
}