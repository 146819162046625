.navbar
{
  width: 100%;
  padding: 0% 5%;
  margin: auto;
  position: fixed;
  left: 0; right: 0; top: 0;
  z-index: 99;
  background: #57AEE3;
  @media screen and (max-width: 767px) {
    background: rgb(90, 97, 102);
  }
  ul
  {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-menu {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        margin: 0 12px;
      }
    }
    li
    {
      list-style: none;
      cursor: pointer;
      &.logo
      {
        width: 100px;
        @media screen and (max-width: 991px) {
          width: 75px;
        }
        @media screen and (max-width: 767px) {
          width: 65px;
        }
        img
        {
          max-width: 100%;
        }
      }
      &.vacancy-link
      {
        a
        {
          color: #ffffff;
          text-decoration: none;
          font-size: 18px; 
          font-family: 'SFUIDisplay Light';
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }
          &::after
          {
            margin-top: 2px;
            display: block;
            content: "";
            width: 0%;
            background: transparent;
            height: 1px;
            transition: width 200ms linear;
          }
          &:hover:after
          {
            width: 100%;
            // background: #4d99c9;
            background: #ffffff;
            transition: width 200ms linear;
          }
        }
      }
    }
  }
  
}

.landing-banner
{
  position: relative;
  padding: 8% 0px 40%;
  display: flex;
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    background-size: cover;
    padding: 10% 0px 38%;
  }
  @media screen and (max-width: 767px) {
    background-size: cover;
    padding: 10% 0px 45%;
    background-position: 50% 0%;
    margin-top: 28px;
  }
  @media screen and (max-width: 499px) {
    background-position: 50% 30px;
    padding: 12% 0px 50%;
  }

  .landing-banner-content
  {
    position: relative;
    z-index: 2;
    width: 60%;
    margin: auto;
    color: #ffffff;
    @media screen and (max-width: 1199px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    h1
    {
      font-family: "SFUIDisplay Bold";
      font-weight: normal;
      margin: 0px 0px 5px; 
      font-size: 35px;
      line-height: 37px;
      @media screen and (max-width: 991px) {
        font-size: 32px;
        line-height: 34px;
      }
      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 30px;
      }
    }
    p
    {
      margin: 0px;
      font-size: 26px;
      font-family: 'SFUIDisplay Light';
      line-height: 30px;
      letter-spacing: .3px;
      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 26px;
      }
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}

.explore-dream
{
  padding: 17% 0px;
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    background-size: cover;
  }
  .explore-dream-content
  {
    width: 60%;
    margin: auto;
    color: #ffffff;
    @media screen and (max-width: 1199px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    h2
    {
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      font-size: 32px;
      line-height: 34px;
      margin: 0px 0px 10px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 24px;
      }
    }
    p
    {
      font-size: 24px;
      line-height: 26px;
      font-family: 'SFUIDisplay Light';
      margin: 0px;
      letter-spacing: .3px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 22px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

.vcc-top
{
  padding: 3% 0px;
  @media screen and (max-width: 767px) {
    padding: 30px 0px;
  }
  .vcc-top-content
  {
    @media screen and (max-width: 767px) {
      width: 90%;
      margin: auto;
    }
    h2
    {
      color: #2b2b2b;
      font-size: 32px;
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      line-height: 34px;
      margin: 0px 0px 5px;
      @media screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 32px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 24px;
      }
    }
    p
    {
      color: #2b2b2b;
      font-size: 24px;
      line-height: 26px;
      font-family: 'SFUIDisplay Light';
      margin: 0px;
      letter-spacing: .3px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 22px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

.vcc-list
{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.our-future-img
{
  padding: 20% 0px;
  background-size: 100%;
  background-position: 50% 100%;
  margin-top: 1%;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}

.our-future
{
  padding: 3% 0px;
  @media screen and (max-width: 767px) {
    padding: 5% 0px;
  }
  .our-future-content
  {
    width: 60%;
    margin: auto;
    @media screen and (max-width: 1199px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    h2
    {
      color: #2b2b2b;
      margin: 0px 0px 5px;
      font-size: 32px;
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      line-height: 34px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 24px;
      }
    }
    p
    {
      color: #2b2b2b;
      margin: 0px;
      font-size: 24px;
      font-family: 'SFUIDisplay Light';
      line-height: 26px;
      letter-spacing: .3px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 22px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

.vacancy
{
  padding: 2% 0px 5%;
}