.previewContainer
{
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  .attachmentItemContainer
  {
    width: 14.66%;
    margin: 1%;
    @media screen and (max-width: 991px) {
      width: calc(25% - 20px);
      margin: 10px;
    }
    @media screen and (max-width: 767px) {
      width: calc(33.33% - 15px);
      margin: 7.5px;
    }
    @media screen and (max-width: 479px) {
      width: calc(50% - 15px);
    }
  }
  .attachmentItem
  {
    position: relative;
    padding: 50%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 6px 0 #eaeaea;
    box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);
    img
    {
      position: absolute;
      top: 0;left: 0;
      bottom: 0;right: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      &.pdfAttachment
      {
        max-width: 65%;
      }
    }
    .infoAttachment
    {
      position: absolute;
      bottom: 0;
      left: 0; right: 0;
      background: #f7f7f7;
      p
      {
        font-family: "SFUIDisplay Light";
        font-size: 12px;
        max-width: 90%;
        margin: 10px auto;
        color: #3b88b9;
      }
    }
  }
}