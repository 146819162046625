.navbar
{
  width: 100%;
  padding: 0% 5%;
  margin: auto;
  position: fixed;
  left: 0; right: 0; top: 0;
  z-index: 99;
  background: #57AEE3;
  @media screen and (max-width: 767px) {
    background: #57AEE3;
  }
  ul
  {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li
    {
      list-style: none;
      cursor: pointer;
      &.logo
      {
        width: 100px;
        @media screen and (max-width: 991px) {
          width: 75px;
        }
        @media screen and (max-width: 767px) {
          width: 65px;
        }
        img
        {
          max-width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  
}