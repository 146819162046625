.referContainer
{
  overflow: hidden;
  padding: 20px 0px 20px;
  position: fixed;
  z-index: 3;
  top: 0; 
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before
  {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
  }
  .boxRefer
  {
    background: #ffffff;
    position: relative;
    z-index: 2;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.12);
    @media screen and (max-width: 767px) {
      width: 80%;
      padding: 20px;
    }
    .btnClose
    {
      position: absolute;
      // background: #ED5742;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      cursor: pointer;
      &:hover
      {
        // background: rgb(247, 117, 100);
      }
      &::before, &::after
      {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        margin: auto;
        content: "";
        width: 70%;
        height: 2px;
        background: #ED5742;
      }
      &::before
      {
        transform: rotate(45deg);
      }
      &::after
      {
        transform: rotate(-45deg);
      }
    }
    .referInfo
    {
      margin: 0px 0px 20px;
      font-size: 16px;
      font-family: 'SFUIDisplay Semibold';
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 16px;
        margin: 0px 0px 10px;
      }
      span
      {
        font-family: 'SFUIDisplay Light';
        @media screen and (max-width: 767px) {
          display: block;
        }
        &:nth-child(2)
        {
          @media screen and (max-width: 767px) {
            display: inline-block;
          }
        }
      }
    }
    form
    {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      @media screen and (max-width: 767px) {
        padding: 0px 10px;
      }
      &>span
      {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      input
      {
        border: 1px solid #3b88b9;
        border-radius: 10px;
        outline: none;
        padding: 5px 10px;
        margin: 0px 10px;
        font-size: 14px;
        font-family: 'SFUIDisplay Light';
        &::placeholder {
          color: #909090;
        }

        @media screen and (max-width: 767px) {
          margin: 10px 0px 0px;
          width: 100%;
          font-size: 12px;
        }
      }
      .btnContainer
      {
        width: 100%;
        display: flex;
        justify-content: center;
        button
        {
          cursor: pointer;
          font-size: 14px;
          font-family: 'SFUIDisplay Light';
          background: #3b88b9;
          outline: none;
          border-radius: 10px;
          border: 1px solid transparent;
          color: #ffffff;
          padding: 5px 15px;
          margin-top: 20px;
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 12px;
          }
        }
        &.disabled
        {
          opacity: .2;
          user-select: none;
          button
          {
            cursor: not-allowed;
          }
        }
      }
      
    }
  }
}