@font-face {
  font-family: 'SFUIDisplay Light';
  src: url(/static/media/sf-ui-display-light.ef1691ea.eot) format('eot'), url(/static/media/sf-ui-display-light.e77aa338.otf) format('opentype'), url(/static/media/sf-ui-display-light.8f9d79cc.ttf) format('truetype');
}
@font-face {
  font-family: 'SFUIDisplay Semibold';
  src: url(/static/media/sf-ui-display-semibold.bc1350ac.eot) format('eot'), url(/static/media/sf-ui-display-semibold.043e3f3a.otf) format('opentype'), url(/static/media/sf-ui-display-semibold.c768faa1.ttf) format('truetype');
}
@font-face {
  font-family: 'SFUIDisplay Bold';
  src: url(/static/media/sf-ui-display-bold.ab566017.eot) format('eot'), url(/static/media/sf-ui-display-bold.14df481e.otf) format('opentype'), url(/static/media/sf-ui-display-bold.bab7061f.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before
{ 
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Vacancy_info__23khf {
  font-size: 16px;
  font-family: "SFUIDisplay Light"; }

.Vacancy_keyword__1ogVk {
  font-size: 16px;
  line-height: 18px;
  font-family: 'SFUIDisplay Light'; }
  @media screen and (max-width: 767px) {
    .Vacancy_keyword__1ogVk {
      font-size: 14px;
      line-height: 16px; } }
  .Vacancy_keyword__1ogVk span {
    font-size: 16px;
    line-height: 18px;
    font-family: 'SFUIDisplay Semibold'; }
    @media screen and (max-width: 767px) {
      .Vacancy_keyword__1ogVk span {
        font-size: 14px;
        line-height: 16px; } }

.Vacancy_moreVacancy__2ZQzI {
  cursor: pointer;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: #3b88b9;
  font-family: 'SFUIDisplay Light';
  font-size: 12px;
  color: #ffffff;
  border: 1px solid transparent;
  outline: none; }
  .Vacancy_moreVacancy__2ZQzI:hover {
    background: #4f9ac9; }
  .Vacancy_moreVacancy__2ZQzI:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }

.Vacancy_vacancyTitle__13xGm {
  margin: 0px 0px 2%; }
  @media screen and (max-width: 991px) {
    .Vacancy_vacancyTitle__13xGm {
      margin: 0px auto 20px;
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .Vacancy_vacancyTitle__13xGm {
      margin: 0px auto 20px;
      width: 90%; } }
  .Vacancy_vacancyTitle__13xGm h2 {
    color: #2b2b2b;
    margin: 0px 0px 5px;
    font-size: 32px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    line-height: 34px; }
    @media screen and (max-width: 991px) {
      .Vacancy_vacancyTitle__13xGm h2 {
        font-size: 28px;
        line-height: 30px; } }
    @media screen and (max-width: 767px) {
      .Vacancy_vacancyTitle__13xGm h2 {
        font-size: 22px;
        line-height: 24px; } }
  .Vacancy_vacancyTitle__13xGm p {
    color: #2b2b2b;
    margin: 0px;
    font-size: 24px;
    font-family: 'SFUIDisplay Light';
    line-height: 26px;
    letter-spacing: .3px; }
    @media screen and (max-width: 991px) {
      .Vacancy_vacancyTitle__13xGm p {
        font-size: 20px;
        line-height: 22px; } }
    @media screen and (max-width: 767px) {
      .Vacancy_vacancyTitle__13xGm p {
        font-size: 16px;
        line-height: 18px; } }

.Vacancy_vacancySearch__2AkIY {
  width: 60%;
  margin: 0px auto 30px; }
  @media screen and (max-width: 1199px) {
    .Vacancy_vacancySearch__2AkIY {
      width: 70%; } }
  @media screen and (max-width: 991px) {
    .Vacancy_vacancySearch__2AkIY {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .Vacancy_vacancySearch__2AkIY {
      width: 90%; } }
  .Vacancy_vacancySearch__2AkIY form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .Vacancy_vacancySearch__2AkIY form input {
      border: 1px solid #3b88b9;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 14px;
      font-family: 'SFUIDisplay Light';
      outline: none; }
      .Vacancy_vacancySearch__2AkIY form input.Vacancy_search__2B2gy {
        width: 55%; }
        @media screen and (max-width: 767px) {
          .Vacancy_vacancySearch__2AkIY form input.Vacancy_search__2B2gy {
            width: 100%; } }
    .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS {
      position: relative;
      cursor: pointer;
      width: 20%;
      border: 1px solid #3b88b9;
      border-radius: 10px;
      margin: 0px auto 0px 20px;
      font-family: 'SFUIDisplay Light';
      outline: none; }
      @media screen and (max-width: 767px) {
        .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS {
          width: 100%;
          margin: 10px auto; } }
      .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS select {
        cursor: pointer;
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        padding: 5px 10px;
        margin: 0px;
        font-family: 'SFUIDisplay Light';
        font-size: 14px;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background: none;
        outline: none;
        border: none; }
        .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS select option {
          font-family: 'SFUIDisplay Light'; }
      .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS div.Vacancy_arrow__2nSad {
        position: absolute;
        z-index: 1;
        content: "";
        display: block;
        width: 25px;
        height: 100%;
        top: 0px;
        right: -1px;
        outline: none;
        border: none;
        background-color: #3b88b9;
        border-radius: 0px 10px 10px 0px; }
        .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS div.Vacancy_arrow__2nSad span.Vacancy_arrowContainer__4ofPr {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 45%;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
      .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS ul {
        width: 100%;
        position: absolute;
        top: 32px;
        left: 0px;
        padding: 0px 0px;
        margin: 0px;
        background-color: #cccccc;
        border-radius: 0px 0px 10px 10px; }
        .Vacancy_vacancySearch__2AkIY form .Vacancy_locationContainer__2PJgS ul li {
          padding: 5px 10px;
          list-style: none;
          color: #ffffff; }
    .Vacancy_vacancySearch__2AkIY form button {
      cursor: pointer;
      font-size: 14px;
      font-family: 'SFUIDisplay Light';
      width: 15%;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid #3b88b9;
      background: #3b88b9;
      color: #ffffff;
      outline: none; }
      @media screen and (max-width: 767px) {
        .Vacancy_vacancySearch__2AkIY form button {
          width: 100%; } }

.Vacancy_vacancyList__3cPBF {
  width: 60%;
  margin: auto;
  padding: 0px; }
  @media screen and (max-width: 1199px) {
    .Vacancy_vacancyList__3cPBF {
      width: 70%; } }
  @media screen and (max-width: 991px) {
    .Vacancy_vacancyList__3cPBF {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .Vacancy_vacancyList__3cPBF {
      width: 90%; } }
  .Vacancy_vacancyList__3cPBF.Vacancy_title__1umWQ {
    background: #3b88b9;
    border-radius: 10px; }
    .Vacancy_vacancyList__3cPBF.Vacancy_title__1umWQ .Vacancy_listItem__3dCw- {
      border: none; }
      .Vacancy_vacancyList__3cPBF.Vacancy_title__1umWQ .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX {
        cursor: default; }
        .Vacancy_vacancyList__3cPBF.Vacancy_title__1umWQ .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_position__1Pqe2 p, .Vacancy_vacancyList__3cPBF.Vacancy_title__1umWQ .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_location__3DyPF p {
          color: #ffffff; }
  .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- {
    border: 1px solid #3b88b9;
    border-radius: 7px;
    margin-top: 20px;
    list-style: none;
    padding: 10px 20px; }
    .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center; }
      .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_position__1Pqe2 {
        width: 55%; }
        @media screen and (max-width: 767px) {
          .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_position__1Pqe2 {
            width: 50%; } }
        .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_position__1Pqe2 p {
          text-align: left;
          margin: 0px;
          color: #909090;
          font-family: 'SFUIDisplay Bold';
          font-size: 16px; }
          @media screen and (max-width: 991px) {
            .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_position__1Pqe2 p {
              font-size: 14px; } }
      .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_location__3DyPF {
        width: 20%;
        margin-left: 20px;
        margin-right: auto; }
        @media screen and (max-width: 767px) {
          .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_location__3DyPF {
            margin-left: 10px;
            width: 24%; } }
        .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_location__3DyPF p {
          text-align: left;
          margin: 0px;
          color: #909090;
          font-family: 'SFUIDisplay Bold';
          font-size: 16px; }
          @media screen and (max-width: 991px) {
            .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_location__3DyPF p {
              font-size: 14px; } }
      .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_details__teajw {
        width: 15%; }
        .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_details__teajw a {
          cursor: pointer;
          margin: 0px;
          color: #3b88b9;
          padding-bottom: 2px;
          border-bottom: 1px solid #3b88b9;
          font-size: 16px; }
          @media screen and (max-width: 991px) {
            .Vacancy_vacancyList__3cPBF .Vacancy_listItem__3dCw- .Vacancy_listContainer__EFuPX .Vacancy_details__teajw a {
              font-size: 14px; } }

.Vacancy_listItem__W3nSw {
  border: 1px solid #3b88b9;
  border-radius: 7px;
  margin-top: 20px;
  list-style: none;
  padding: 10px 20px; }
  .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center; }
    .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_position__1gmDC {
      width: 55%; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_position__1gmDC {
          width: 50%; } }
      .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_position__1gmDC p {
        text-align: left;
        margin: 0px;
        color: #909090;
        font-size: 16px;
        font-family: 'SFUIDisplay Semibold'; }
        @media screen and (max-width: 991px) {
          .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_position__1gmDC p {
            font-size: 14px; } }
    .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_location__1LVlJ {
      width: 20%;
      margin-left: 20px;
      margin-right: auto; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_location__1LVlJ {
          margin-left: 10px;
          width: 24%; } }
      .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_location__1LVlJ p {
        text-align: left;
        margin: 0px;
        color: #909090;
        font-family: 'SFUIDisplay Semibold';
        font-size: 16px; }
        @media screen and (max-width: 991px) {
          .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_location__1LVlJ p {
            font-size: 14px; } }
    .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh {
      width: 15%;
      display: flex;
      justify-content: center; }
      .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh a {
        cursor: pointer;
        display: block;
        margin: 0px;
        color: #3b88b9;
        padding-bottom: 2px;
        text-decoration: none;
        font-size: 16px;
        font-family: 'SFUIDisplay Light';
        -webkit-transition: -webkit-transform 200ms linear;
        transition: -webkit-transform 200ms linear;
        transition: transform 200ms linear;
        transition: transform 200ms linear, -webkit-transform 200ms linear; }
        @media screen and (max-width: 991px) {
          .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh a {
            font-size: 14px; } }
        .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh a:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: #8ec6e5;
          -webkit-transition: -webkit-transform 200ms linear;
          transition: -webkit-transform 200ms linear;
          transition: transform 200ms linear;
          transition: transform 200ms linear, -webkit-transform 200ms linear; }
        .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh a:hover {
          -webkit-transform: translateY(-2px);
                  transform: translateY(-2px);
          -webkit-transition: -webkit-transform 200ms linear;
          transition: -webkit-transform 200ms linear;
          transition: transform 200ms linear;
          transition: transform 200ms linear, -webkit-transform 200ms linear; }
          .Vacancy_listItem__W3nSw .Vacancy_listContainer__3nEqa .Vacancy_details__aukXh a:hover:after {
            -webkit-transform: translateY(2px);
                    transform: translateY(2px);
            -webkit-transition: -webkit-transform 200ms linear;
            transition: -webkit-transform 200ms linear;
            transition: transform 200ms linear;
            transition: transform 200ms linear, -webkit-transform 200ms linear; }
  .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa {
    text-align: left !important; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa.Vacancy_hide__1DE_O {
      max-height: 0px;
      padding: 0px;
      overflow: hidden;
      opacity: 0; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa.Vacancy_show__1ROpf {
      height: auto;
      max-height: 10000px;
      padding: 0px 0px 20px;
      -webkit-transition: all 500ms linear;
      transition: all 500ms linear;
      opacity: 1; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_title__3qa1K, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_descript__2_JLl {
      color: #2b2b2b;
      margin: 0px;
      text-align: left; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_title__3qa1K {
      border-top: 1px solid #3b88b9;
      margin-top: 10px;
      padding-top: 25px;
      font-family: 'SFUIDisplay Bold';
      font-size: 16px; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_title__3qa1K {
          font-size: 14px; } }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 h4, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 h4 {
      color: #2b2b2b;
      text-align: left;
      margin: 5px 0px 10px;
      font-family: 'SFUIDisplay Light';
      font-weight: normal;
      font-size: 16px; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 h4, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 h4 {
          font-size: 14px; } }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 p, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 p {
      font-size: 16px;
      line-height: 18px;
      font-family: 'SFUIDisplay Light';
      text-align: left; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 p, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 p {
          font-size: 14px;
          line-height: 16px; } }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 ul, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 ul {
      padding: 10px 20px 0px; }
      .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 ul li, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 ul li {
        font-size: 16px;
        font-family: 'SFUIDisplay Light';
        list-style: disc;
        text-align: left;
        margin: 5px 0px; }
        @media screen and (max-width: 767px) {
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_jobDesc__2pR01 ul li, .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 ul li {
            font-size: 14px;
            line-height: 16px; } }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_require__1Qaj0 h4 {
      margin-top: 50px; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y {
      padding: 30px 0px 20px;
      display: flex;
      justify-content: center; }
      @media screen and (max-width: 767px) {
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y {
          flex-flow: column; } }
      .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button {
        margin: 0 3%;
        padding: 10px 20px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: #ffffff;
        font-size: 14px;
        font-family: 'SFUIDisplay Semibold';
        cursor: pointer; }
        @media screen and (max-width: 767px) {
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button {
            font-size: 12px;
            margin-top: 10px; } }
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button.Vacancy_interest__1DLES {
          background: #3b88b9; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button.Vacancy_interest__1DLES:hover {
            background: #4f96c2; }
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button.Vacancy_refer__1fIng {
          background: #2b2b2b; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_btnSelection__2o85y button.Vacancy_refer__1fIng:hover {
            background: #444444; }
    .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr {
      overflow: hidden;
      padding: 20px 0px 20px; }
      .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr .Vacancy_referInfo__2Mw_z {
        font-size: 16px;
        font-family: 'SFUIDisplay Light'; }
        @media screen and (max-width: 767px) {
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr .Vacancy_referInfo__2Mw_z {
            font-size: 14px;
            margin: 20px 0px 10px; } }
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr .Vacancy_referInfo__2Mw_z span {
          font-family: 'SFUIDisplay Semibold'; }
      .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form {
        display: flex;
        flex-flow: row wrap;
        justify-content: center; }
        @media screen and (max-width: 767px) {
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form {
            padding: 0px 10px; } }
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form input {
          border: 1px solid #3b88b9;
          border-radius: 10px;
          outline: none;
          padding: 5px 10px;
          margin: 0px 10px;
          font-size: 14px;
          font-family: 'SFUIDisplay Light'; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form input::-webkit-input-placeholder {
            color: #909090; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form input::-ms-input-placeholder {
            color: #909090; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form input::placeholder {
            color: #909090; }
          @media screen and (max-width: 767px) {
            .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form input {
              margin: 10px 0px 0px;
              width: 100%;
              font-size: 12px; } }
        .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form .Vacancy_btnContainer__2VrPP {
          width: 100%;
          display: flex;
          justify-content: center; }
          .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form .Vacancy_btnContainer__2VrPP button {
            cursor: pointer;
            font-size: 14px;
            font-family: 'SFUIDisplay Light';
            background: #3b88b9;
            outline: none;
            border-radius: 10px;
            border: 1px solid transparent;
            color: #ffffff;
            padding: 5px 15px;
            margin-top: 10px; }
            @media screen and (max-width: 767px) {
              .Vacancy_listItem__W3nSw .Vacancy_detailInfo__34rVa .Vacancy_referContainer__2tgsr form .Vacancy_btnContainer__2VrPP button {
                width: 100%;
                font-size: 12px; } }

.ModalInfo_modalContainer__l8WC_ {
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); }
  .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA {
    background: #f9f9f9;
    position: relative;
    z-index: 2;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.12); }
    @media screen and (max-width: 767px) {
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA {
        width: 80%;
        padding: 20px; } }
    @media screen and (max-width: 479px) {
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA {
        padding: 40px 20px 20px; } }
    .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA .ModalInfo_btnClose__3t3P1 {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      cursor: pointer; }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA .ModalInfo_btnClose__3t3P1::before, .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA .ModalInfo_btnClose__3t3P1::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 70%;
        height: 2px;
        background: #ED5742; }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA .ModalInfo_btnClose__3t3P1::before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA .ModalInfo_btnClose__3t3P1::after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA p {
      font-family: "SFUIDisplay Light";
      font-size: 14px;
      margin: 0px;
      color: #333333; }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA p span {
        display: block;
        margin: 10px 0px 20px;
        font-family: "SFUIDisplay SemiBold";
        font-size: 16px; }
    .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button {
      cursor: pointer;
      outline: none;
      border: 1px solid transparent;
      border-radius: 5px;
      padding: 10px 15px;
      margin: 0px 10px;
      color: #ffffff;
      box-shadow: 0 1px 6px 0 #31353b1f;
      box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12); }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_new__3lysA {
        background: #469bfd;
        background: #ffffff;
        color: #333333; }
        @media screen and (max-width: 767px) {
          .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_new__3lysA {
            width: 200px; } }
        @media screen and (max-width: 479px) {
          .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_new__3lysA {
            width: 150px; } }
        .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_new__3lysA:hover {
          background: #368bec;
          background: #f5f5f5; }
      .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_edit__2tqOJ {
        background: #47c969;
        background: #469bfd; }
        @media screen and (max-width: 767px) {
          .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_edit__2tqOJ {
            display: block;
            margin: 10px auto 0px;
            width: 200px; } }
        @media screen and (max-width: 479px) {
          .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_edit__2tqOJ {
            width: 150px; } }
        .ModalInfo_modalContainer__l8WC_ .ModalInfo_modalPlc__H8brA button.ModalInfo_edit__2tqOJ:hover {
          background: #1ea542;
          background: #368bec; }

.ReferFriend_referContainer__2fBzT {
  overflow: hidden;
  padding: 20px 0px 20px;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ReferFriend_referContainer__2fBzT::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); }
  .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 {
    background: #ffffff;
    position: relative;
    z-index: 2;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.12); }
    @media screen and (max-width: 767px) {
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 {
        width: 80%;
        padding: 20px; } }
    .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_btnClose__3L0Sf {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      cursor: pointer; }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_btnClose__3L0Sf::before, .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_btnClose__3L0Sf::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 70%;
        height: 2px;
        background: #ED5742; }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_btnClose__3L0Sf::before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_btnClose__3L0Sf::after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_referInfo__5IGR2 {
      margin: 0px 0px 20px;
      font-size: 16px;
      font-family: 'SFUIDisplay Semibold'; }
      @media screen and (max-width: 767px) {
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_referInfo__5IGR2 {
          font-size: 14px;
          line-height: 16px;
          margin: 0px 0px 10px; } }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_referInfo__5IGR2 span {
        font-family: 'SFUIDisplay Light'; }
        @media screen and (max-width: 767px) {
          .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_referInfo__5IGR2 span {
            display: block; } }
        @media screen and (max-width: 767px) {
          .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 .ReferFriend_referInfo__5IGR2 span:nth-child(2) {
            display: inline-block; } }
    .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form {
      display: flex;
      flex-flow: row wrap;
      justify-content: center; }
      @media screen and (max-width: 767px) {
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form {
          padding: 0px 10px; } }
      @media screen and (max-width: 767px) {
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form > span {
          width: 100%; } }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form input {
        border: 1px solid #3b88b9;
        border-radius: 10px;
        outline: none;
        padding: 5px 10px;
        margin: 0px 10px;
        font-size: 14px;
        font-family: 'SFUIDisplay Light'; }
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form input::-webkit-input-placeholder {
          color: #909090; }
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form input::-ms-input-placeholder {
          color: #909090; }
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form input::placeholder {
          color: #909090; }
        @media screen and (max-width: 767px) {
          .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form input {
            margin: 10px 0px 0px;
            width: 100%;
            font-size: 12px; } }
      .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form .ReferFriend_btnContainer__3CsGZ {
        width: 100%;
        display: flex;
        justify-content: center; }
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form .ReferFriend_btnContainer__3CsGZ button {
          cursor: pointer;
          font-size: 14px;
          font-family: 'SFUIDisplay Light';
          background: #3b88b9;
          outline: none;
          border-radius: 10px;
          border: 1px solid transparent;
          color: #ffffff;
          padding: 5px 15px;
          margin-top: 20px; }
          @media screen and (max-width: 767px) {
            .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form .ReferFriend_btnContainer__3CsGZ button {
              width: 100%;
              font-size: 12px; } }
        .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form .ReferFriend_btnContainer__3CsGZ.ReferFriend_disabled__1O5i3 {
          opacity: .2;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          .ReferFriend_referContainer__2fBzT .ReferFriend_boxRefer__1hM00 form .ReferFriend_btnContainer__3CsGZ.ReferFriend_disabled__1O5i3 button {
            cursor: not-allowed; }

.Input_inputElement__LjolS li.Input_radioInput__hjDbP {
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 5px 0px; }
  .Input_inputElement__LjolS li.Input_radioInput__hjDbP .Input_check__3X2Am {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 25px;
    height: 25px;
    margin: auto 0px;
    border-radius: 100%;
    border: 2px solid #7cabc9; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP .Input_check__3X2Am::before {
      position: absolute;
      content: '';
      top: 3px;
      left: 3px;
      width: 15px;
      height: 15px;
      margin: auto;
      background: transparent;
      border-radius: 100%; }
  .Input_inputElement__LjolS li.Input_radioInput__hjDbP label {
    width: 100px;
    padding-left: 15px;
    z-index: 2;
    cursor: pointer;
    color: #909090;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; }
  .Input_inputElement__LjolS li.Input_radioInput__hjDbP input {
    position: absolute;
    width: 20px;
    margin: 0px 7px 0px 0px;
    visibility: hidden; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP input:checked {
      color: red; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP input:checked ~ label {
      color: #2b2b2b; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP input:checked ~ .Input_check__3X2Am {
      border-color: #3b88b9; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP input:checked ~ .Input_check__3X2Am::before {
      background: #3b88b9; }
  .Input_inputElement__LjolS li.Input_radioInput__hjDbP:hover .Input_check__3X2Am {
    border-color: #3b88b9;
    -webkit-transition: border 250ms linear;
    transition: border 250ms linear; }
    .Input_inputElement__LjolS li.Input_radioInput__hjDbP:hover .Input_check__3X2Am::before {
      -webkit-transition: background 250ms linear;
      transition: background 250ms linear; }
  .Input_inputElement__LjolS li.Input_radioInput__hjDbP:hover label {
    color: #2b2b2b;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear; }

.Input_inputElement__LjolS ul.Input_knowBy__35HX9 {
  display: none;
  width: 200px;
  position: absolute;
  left: calc(100% + 40px);
  top: 0px;
  margin: 0px;
  padding-left: 0px;
  background: #ffffff;
  border: 1px solid #63aad8;
  border-radius: 0px 0px 5px 5px; }
  @media screen and (max-width: 767px) {
    .Input_inputElement__LjolS ul.Input_knowBy__35HX9 {
      margin-top: 0px;
      width: 100%;
      position: relative;
      left: unset;
      right: unset;
      border-top: 0px; } }
  .Input_inputElement__LjolS ul.Input_knowBy__35HX9 span.Input_blueArrow__yu00A {
    content: "";
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
    position: absolute;
    top: 5px;
    left: -18px;
    z-index: 9; }
    @media screen and (max-width: 767px) {
      .Input_inputElement__LjolS ul.Input_knowBy__35HX9 span.Input_blueArrow__yu00A {
        display: none; } }
  .Input_inputElement__LjolS ul.Input_knowBy__35HX9.Input_show__W5bAz {
    display: block; }
  .Input_inputElement__LjolS ul.Input_knowBy__35HX9 li {
    cursor: pointer;
    list-style: none;
    padding: 10px 15px;
    margin: 0px;
    text-align: left;
    color: #195c87;
    font-size: 14px; }
    .Input_inputElement__LjolS ul.Input_knowBy__35HX9 li:hover {
      background: #63aad8;
      color: #ffffff;
      -webkit-transition: all 150ms linear;
      transition: all 150ms linear; }

.Input_invalid__1O_p3 {
  border-color: #f50e0e !important; }

.Input_valid__1yQRh {
  border-color: #3bb93b !important; }

.Input_feedback__1Nu6a p {
  font-size: 12px;
  color: #f50e0e;
  margin: 5px 0px 0px !important; }

.VCCList_vccItem__1wHoy {
  cursor: pointer;
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat; }
  .VCCList_vccItem__1wHoy .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz h2 {
    margin: 0px 0px 5px;
    font-family: 'SFUIDisplay Bold';
    font-size: 26px;
    line-height: 28px;
    color: #2b2b2b; }
    @media screen and (max-width: 991px) {
      .VCCList_vccItem__1wHoy .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz h2 {
        font-size: 18px;
        line-height: 20px; } }
  .VCCList_vccItem__1wHoy .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz p {
    margin: 0px;
    font-size: 20px;
    font-family: 'SFUIDisplay light';
    line-height: 22px;
    color: #2b2b2b; }
    @media screen and (max-width: 991px) {
      .VCCList_vccItem__1wHoy .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz p {
        font-size: 16px;
        line-height: 18px; } }
  .VCCList_vccItem__1wHoy.VCCList_vcc1__2HhX0, .VCCList_vccItem__1wHoy.VCCList_vcc2__DzT5M, .VCCList_vccItem__1wHoy.VCCList_vcc4__gUPnT, .VCCList_vccItem__1wHoy.VCCList_vcc5__36-k1 {
    width: 49.5%;
    padding: 2% 0px 22%; }
    @media screen and (max-width: 767px) {
      .VCCList_vccItem__1wHoy.VCCList_vcc1__2HhX0, .VCCList_vccItem__1wHoy.VCCList_vcc2__DzT5M, .VCCList_vccItem__1wHoy.VCCList_vcc4__gUPnT, .VCCList_vccItem__1wHoy.VCCList_vcc5__36-k1 {
        width: 100%;
        padding: 5% 5% 45%;
        margin-top: 10px; } }
  .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk {
    width: 100%;
    margin: 1% 0px;
    background-size: 100%;
    padding: 0px; }
    @media screen and (max-width: 1199px) {
      .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk {
        background-size: cover; } }
    @media screen and (max-width: 767px) {
      .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk {
        position: relative;
        padding: 5% 0px 45%;
        margin: 10px 0px 0px; } }
    @media screen and (max-width: 767px) {
      .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ::after, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        background: #56aee1;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(32%, #56aee1), color-stop(80%, rgba(213, 235, 248, 0)), to(rgba(255, 255, 255, 0)));
        background: linear-gradient(180deg, #56aee1 32%, rgba(213, 235, 248, 0) 80%, rgba(255, 255, 255, 0) 100%); } }
    .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ .VCCList_vccWrapper__3S3xo, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk .VCCList_vccWrapper__3S3xo {
      padding: 12% 0px;
      width: 49.5%; }
      @media screen and (max-width: 767px) {
        .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ .VCCList_vccWrapper__3S3xo, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk .VCCList_vccWrapper__3S3xo {
          width: 100%;
          padding: 0px;
          position: relative;
          z-index: 2; } }
      .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz h2, .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz p, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz h2, .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk .VCCList_vccWrapper__3S3xo .VCCList_vccContent__3K_Nz p {
        color: #ffffff; }
  @media screen and (max-width: 1199px) {
    .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ {
      background-position: 0% 100%; } }
  @media screen and (max-width: 767px) {
    .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ {
      background-position: 0% 0%; } }
  .VCCList_vccItem__1wHoy.VCCList_vcc3__yrlhQ .VCCList_vccWrapper__3S3xo {
    margin-left: auto; }
  @media screen and (max-width: 1199px) {
    .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk {
      background-position: 100% 100%; } }
  @media screen and (max-width: 767px) {
    .VCCList_vccItem__1wHoy.VCCList_vcc6__3_ltk {
      margin-bottom: 10px; } }

.Loader_spinnerContainer__1Jf50 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #9FCBFF;
  background: linear-gradient(147deg, #9EE6FC 0%, #9FCBFF 100%);
  z-index: 100; }
  .Loader_spinnerContainer__1Jf50 .Loader_imgContainer__3s6AQ {
    width: 120px;
    margin: 0px auto 5px; }
    .Loader_spinnerContainer__1Jf50 .Loader_imgContainer__3s6AQ img {
      width: 100%; }
  .Loader_spinnerContainer__1Jf50 .Loader_loadingBar__3IXYb {
    position: relative;
    width: 150px;
    background: #ffffff;
    height: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center; }
    .Loader_spinnerContainer__1Jf50 .Loader_loadingBar__3IXYb span {
      position: absolute;
      content: "";
      height: 100%;
      top: 0;
      left: 0;
      background: #3e8fc2;
      -webkit-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out; }

.navbar {
  width: 100%;
  padding: 0% 5%;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  background: #57AEE3; }
  @media screen and (max-width: 767px) {
    .navbar {
      background: #5a6166; } }
  .navbar ul {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .navbar ul .navbar-menu {
      display: flex;
      align-items: center; }
      .navbar ul .navbar-menu img {
        width: 28px;
        height: 28px;
        margin: 0 12px; }
    .navbar ul li {
      list-style: none;
      cursor: pointer; }
      .navbar ul li.logo {
        width: 100px; }
        @media screen and (max-width: 991px) {
          .navbar ul li.logo {
            width: 75px; } }
        @media screen and (max-width: 767px) {
          .navbar ul li.logo {
            width: 65px; } }
        .navbar ul li.logo img {
          max-width: 100%; }
      .navbar ul li.vacancy-link a {
        color: #ffffff;
        text-decoration: none;
        font-size: 18px;
        font-family: 'SFUIDisplay Light'; }
        @media screen and (max-width: 991px) {
          .navbar ul li.vacancy-link a {
            font-size: 16px; } }
        .navbar ul li.vacancy-link a::after {
          margin-top: 2px;
          display: block;
          content: "";
          width: 0%;
          background: transparent;
          height: 1px;
          -webkit-transition: width 200ms linear;
          transition: width 200ms linear; }
        .navbar ul li.vacancy-link a:hover:after {
          width: 100%;
          background: #ffffff;
          -webkit-transition: width 200ms linear;
          transition: width 200ms linear; }

.landing-banner {
  position: relative;
  padding: 8% 0px 40%;
  display: flex;
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat; }
  @media screen and (max-width: 991px) {
    .landing-banner {
      background-size: cover;
      padding: 10% 0px 38%; } }
  @media screen and (max-width: 767px) {
    .landing-banner {
      background-size: cover;
      padding: 10% 0px 45%;
      background-position: 50% 0%;
      margin-top: 28px; } }
  @media screen and (max-width: 499px) {
    .landing-banner {
      background-position: 50% 30px;
      padding: 12% 0px 50%; } }
  .landing-banner .landing-banner-content {
    position: relative;
    z-index: 2;
    width: 60%;
    margin: auto;
    color: #ffffff; }
    @media screen and (max-width: 1199px) {
      .landing-banner .landing-banner-content {
        width: 70%; } }
    @media screen and (max-width: 991px) {
      .landing-banner .landing-banner-content {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      .landing-banner .landing-banner-content {
        width: 90%; } }
    .landing-banner .landing-banner-content h1 {
      font-family: "SFUIDisplay Bold";
      font-weight: normal;
      margin: 0px 0px 5px;
      font-size: 35px;
      line-height: 37px; }
      @media screen and (max-width: 991px) {
        .landing-banner .landing-banner-content h1 {
          font-size: 32px;
          line-height: 34px; } }
      @media screen and (max-width: 767px) {
        .landing-banner .landing-banner-content h1 {
          font-size: 28px;
          line-height: 30px; } }
    .landing-banner .landing-banner-content p {
      margin: 0px;
      font-size: 26px;
      font-family: 'SFUIDisplay Light';
      line-height: 30px;
      letter-spacing: .3px; }
      @media screen and (max-width: 991px) {
        .landing-banner .landing-banner-content p {
          font-size: 24px;
          line-height: 26px; } }
      @media screen and (max-width: 767px) {
        .landing-banner .landing-banner-content p {
          font-size: 20px;
          line-height: 22px; } }

.explore-dream {
  padding: 17% 0px;
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat; }
  @media screen and (max-width: 991px) {
    .explore-dream {
      background-size: cover; } }
  .explore-dream .explore-dream-content {
    width: 60%;
    margin: auto;
    color: #ffffff; }
    @media screen and (max-width: 1199px) {
      .explore-dream .explore-dream-content {
        width: 70%; } }
    @media screen and (max-width: 991px) {
      .explore-dream .explore-dream-content {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      .explore-dream .explore-dream-content {
        width: 90%; } }
    .explore-dream .explore-dream-content h2 {
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      font-size: 32px;
      line-height: 34px;
      margin: 0px 0px 10px; }
      @media screen and (max-width: 991px) {
        .explore-dream .explore-dream-content h2 {
          font-size: 28px;
          line-height: 30px; } }
      @media screen and (max-width: 767px) {
        .explore-dream .explore-dream-content h2 {
          font-size: 22px;
          line-height: 24px; } }
    .explore-dream .explore-dream-content p {
      font-size: 24px;
      line-height: 26px;
      font-family: 'SFUIDisplay Light';
      margin: 0px;
      letter-spacing: .3px; }
      @media screen and (max-width: 991px) {
        .explore-dream .explore-dream-content p {
          font-size: 20px;
          line-height: 22px; } }
      @media screen and (max-width: 767px) {
        .explore-dream .explore-dream-content p {
          font-size: 16px;
          line-height: 18px; } }

.vcc-top {
  padding: 3% 0px; }
  @media screen and (max-width: 767px) {
    .vcc-top {
      padding: 30px 0px; } }
  @media screen and (max-width: 767px) {
    .vcc-top .vcc-top-content {
      width: 90%;
      margin: auto; } }
  .vcc-top .vcc-top-content h2 {
    color: #2b2b2b;
    font-size: 32px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    line-height: 34px;
    margin: 0px 0px 5px; }
    @media screen and (max-width: 991px) {
      .vcc-top .vcc-top-content h2 {
        font-size: 30px;
        line-height: 32px; } }
    @media screen and (max-width: 767px) {
      .vcc-top .vcc-top-content h2 {
        font-size: 22px;
        line-height: 24px; } }
  .vcc-top .vcc-top-content p {
    color: #2b2b2b;
    font-size: 24px;
    line-height: 26px;
    font-family: 'SFUIDisplay Light';
    margin: 0px;
    letter-spacing: .3px; }
    @media screen and (max-width: 991px) {
      .vcc-top .vcc-top-content p {
        font-size: 20px;
        line-height: 22px; } }
    @media screen and (max-width: 767px) {
      .vcc-top .vcc-top-content p {
        font-size: 16px;
        line-height: 18px; } }

.vcc-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.our-future-img {
  padding: 20% 0px;
  background-size: 100%;
  background-position: 50% 100%;
  margin-top: 1%; }
  @media screen and (max-width: 767px) {
    .our-future-img {
      margin-top: 10px; } }

.our-future {
  padding: 3% 0px; }
  @media screen and (max-width: 767px) {
    .our-future {
      padding: 5% 0px; } }
  .our-future .our-future-content {
    width: 60%;
    margin: auto; }
    @media screen and (max-width: 1199px) {
      .our-future .our-future-content {
        width: 70%; } }
    @media screen and (max-width: 991px) {
      .our-future .our-future-content {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      .our-future .our-future-content {
        width: 90%; } }
    .our-future .our-future-content h2 {
      color: #2b2b2b;
      margin: 0px 0px 5px;
      font-size: 32px;
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      line-height: 34px; }
      @media screen and (max-width: 991px) {
        .our-future .our-future-content h2 {
          font-size: 28px;
          line-height: 30px; } }
      @media screen and (max-width: 767px) {
        .our-future .our-future-content h2 {
          font-size: 22px;
          line-height: 24px; } }
    .our-future .our-future-content p {
      color: #2b2b2b;
      margin: 0px;
      font-size: 24px;
      font-family: 'SFUIDisplay Light';
      line-height: 26px;
      letter-spacing: .3px; }
      @media screen and (max-width: 991px) {
        .our-future .our-future-content p {
          font-size: 20px;
          line-height: 22px; } }
      @media screen and (max-width: 767px) {
        .our-future .our-future-content p {
          font-size: 16px;
          line-height: 18px; } }

.vacancy {
  padding: 2% 0px 5%; }

.Interest_interestForm__1cGUD {
  padding: 83.66px 5% 20px; }

.FilePreview_previewContainer__mr38C {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline; }
  .FilePreview_previewContainer__mr38C .FilePreview_attachmentItemContainer__3Ntrc {
    width: 14.66%;
    margin: 1%; }
    @media screen and (max-width: 991px) {
      .FilePreview_previewContainer__mr38C .FilePreview_attachmentItemContainer__3Ntrc {
        width: calc(25% - 20px);
        margin: 10px; } }
    @media screen and (max-width: 767px) {
      .FilePreview_previewContainer__mr38C .FilePreview_attachmentItemContainer__3Ntrc {
        width: calc(33.33% - 15px);
        margin: 7.5px; } }
    @media screen and (max-width: 479px) {
      .FilePreview_previewContainer__mr38C .FilePreview_attachmentItemContainer__3Ntrc {
        width: calc(50% - 15px); } }
  .FilePreview_previewContainer__mr38C .FilePreview_attachmentItem__IX7DD {
    position: relative;
    padding: 50%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 6px 0 #eaeaea;
    box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12); }
    .FilePreview_previewContainer__mr38C .FilePreview_attachmentItem__IX7DD img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%; }
      .FilePreview_previewContainer__mr38C .FilePreview_attachmentItem__IX7DD img.FilePreview_pdfAttachment__VAcIa {
        max-width: 65%; }
    .FilePreview_previewContainer__mr38C .FilePreview_attachmentItem__IX7DD .FilePreview_infoAttachment__2Ut8w {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f7f7f7; }
      .FilePreview_previewContainer__mr38C .FilePreview_attachmentItem__IX7DD .FilePreview_infoAttachment__2Ut8w p {
        font-family: "SFUIDisplay Light";
        font-size: 12px;
        max-width: 90%;
        margin: 10px auto;
        color: #3b88b9; }

.Upload_section__3hNu0.Upload_upload__yiKs3 {
  width: 100%; }
  .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_note__bWTma {
    color: red;
    font-size: 14px;
    text-align: left;
    margin: 5px 0px 0px; }
    @media screen and (max-width: 767px) {
      .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_note__bWTma {
        font-size: 12px; } }
    .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_note__bWTma.Upload_optional__37GsA {
      color: #3b88b9; }
  .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 20px 0px 0px; }
    .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_info__1BR_F {
      color: #3b88b9;
      font-size: 14px;
      margin: 0px;
      padding: 5px 80px 5px 15px;
      border: 1px solid #3b88b9;
      border-radius: 7px;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_info__1BR_F {
          width: calc(100% - 40px); } }
    .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 input {
      display: none; }
    .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_btnContainer__23ElO {
      display: block;
      width: 30px;
      height: 100%;
      margin-left: 15px; }
      @media screen and (max-width: 767px) {
        .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_btnContainer__23ElO {
          width: 25px;
          margin-left: 15px; } }
      .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_btnContainer__23ElO button {
        cursor: pointer;
        padding: 50%;
        background-color: #3b88b9;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: none;
        border-radius: 7px;
        outline: none;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        box-shadow: 0 5px 5px 0px #2b2b2b; }
        .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_btnContainer__23ElO button:hover {
          background-color: #4e98c7; }
        .Upload_section__3hNu0.Upload_upload__yiKs3 .Upload_btnUpload__3wh75 .Upload_btnContainer__23ElO button:active {
          box-shadow: 0 2px #2b2b2b;
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1); }

.FormInput_form__13RC2 {
  display: flex;
  flex-flow: row wrap; }
  .FormInput_form__13RC2 .FormInput_info__2RKlG {
    color: red;
    width: 100%;
    text-align: left;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .FormInput_form__13RC2 .FormInput_info__2RKlG {
        font-size: 12px;
        display: block;
        margin-bottom: 50px !important; } }
  .FormInput_form__13RC2 .FormInput_section__PuGN4 {
    width: 100%;
    margin-top: 20px; }
    @media screen and (max-width: 767px) {
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_buttonInfo__3NfBX {
        flex-flow: row wrap; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_row__3AZMC {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_row__3AZMC > span {
        display: flex;
        flex-flow: column;
        margin-right: 20px;
        width: 29%; }
        @media screen and (max-width: 991px) {
          .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_row__3AZMC > span {
            margin-right: 15px; } }
        @media screen and (max-width: 767px) {
          .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_row__3AZMC > span {
            width: 100%;
            margin: 5px 0px; } }
        .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_row__3AZMC > span input {
          width: 100%;
          border: 1px solid #3b88b9;
          padding: 5px 15px;
          font-size: 14px;
          font-family: 'SFUIDisplay Light';
          border-radius: 10px;
          outline: none; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4 .FormInput_selectionRadio__2Ib9l {
      display: flex;
      flex-flow: row wrap;
      padding-left: 0px;
      margin: 5px 0px; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4 h4 {
      width: 100%;
      text-align: left;
      margin: 5px 0px;
      font-family: 'SFUIDisplay Bold'; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4 h4.FormInput_semiBold__1uHtV {
        font-family: 'SFUIDisplay Semibold'; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4 p {
      text-align: left;
      margin: 10px 0px; }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4 p.FormInput_provide__2S3h6 {
          font-size: 16px; } }
      @media screen and (max-width: 479px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4 p.FormInput_provide__2S3h6 {
          font-size: 14px; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_email__2R900 .FormInput_row__3AZMC > span, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_phone__-kPdr .FormInput_row__3AZMC > span, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_salary__1ctGB .FormInput_row__3AZMC > span {
      width: calc(58% + 20px); }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_email__2R900 .FormInput_row__3AZMC > span, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_phone__-kPdr .FormInput_row__3AZMC > span, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_salary__1ctGB .FormInput_row__3AZMC > span {
          width: 100%;
          margin: 5px 0px; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_highSchool__1nKHH {
      overflow: hidden;
      margin: 0px;
      max-height: 0px;
      -webkit-transition: 300ms;
      transition: 300ms; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_show__geF-s {
      max-height: none;
      margin-top: 20px;
      -webkit-transition: 300ms;
      transition: 300ms; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP {
      position: relative;
      z-index: 10;
      width: calc(58% + 20px); }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP {
          width: 100%; } }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP.FormInput_row__3AZMC > span {
        width: 100%;
        margin: 0px; }
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP.FormInput_row__3AZMC > span > div > div {
          width: 100%; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP > div:nth-child(1) {
        width: 100%; }
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP > div:nth-child(1) > div {
          width: 100%; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_start__1HlAh .FormInput_datePicker__2iUpP input {
        cursor: pointer;
        width: 100%; }
    @media screen and (max-width: 767px) {
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_row__3AZMC, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_row__3AZMC, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_row__3AZMC {
        position: relative;
        padding: 10px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin-bottom: 40px; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_row__3AZMC:not(:nth-of-type(1)), .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_row__3AZMC:not(:nth-of-type(1)), .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_row__3AZMC:not(:nth-of-type(1)) {
      margin-top: 15px; }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_row__3AZMC:not(:nth-of-type(1)), .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_row__3AZMC:not(:nth-of-type(1)), .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_row__3AZMC:not(:nth-of-type(1)) {
          margin-top: 60px; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_btnContainer__34Lu0, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_btnContainer__34Lu0, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_btnContainer__34Lu0 {
      display: block;
      width: 22px;
      height: 22px;
      margin: 0px 5px !important; }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_btnContainer__34Lu0, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_btnContainer__34Lu0, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_btnContainer__34Lu0 {
          height: 30px;
          margin: 5px 5px !important;
          position: absolute;
          top: calc(100% + 5px);
          left: 0px; } }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_btnContainer__34Lu0 button, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_btnContainer__34Lu0 button, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_btnContainer__34Lu0 button {
        cursor: pointer;
        padding: 50%;
        background-color: #3b88b9;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: none;
        border-radius: 7px;
        outline: none;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        box-shadow: 0 5px 2px 0px #2b2b2b;
        box-shadow: 0 5px 2px 0 rgba(49, 53, 59, 0.5); }
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_btnContainer__34Lu0 button:hover, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_btnContainer__34Lu0 button:hover, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_btnContainer__34Lu0 button:hover {
          background-color: #4e98c7; }
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_university__G6k4t .FormInput_btnContainer__34Lu0 button:active, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_experience__VTCtG .FormInput_btnContainer__34Lu0 button:active, .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_newUniv__Hd8Xo .FormInput_btnContainer__34Lu0 button:active {
          box-shadow: 0 2px #2b2b2b;
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_howKnow__Ddz8m .FormInput_row__3AZMC {
      position: relative;
      width: calc(58% + 20px); }
      @media screen and (max-width: 767px) {
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_howKnow__Ddz8m .FormInput_row__3AZMC {
          width: auto; } }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_howKnow__Ddz8m .FormInput_row__3AZMC p {
        position: relative;
        cursor: pointer;
        width: 100%;
        padding: 5px 15px;
        margin: 0px;
        text-align: left;
        font-size: 14px;
        border: 1px solid #3b88b9;
        border-radius: 10px;
        overflow: hidden; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_howKnow__Ddz8m .FormInput_row__3AZMC .FormInput_arrow__BujE4 {
        position: absolute;
        top: 0;
        right: 0px;
        content: "";
        display: block;
        width: 35px;
        height: 100%;
        background-color: #3b88b9;
        background-size: 30%;
        background-position: 50% 50%;
        background-repeat: no-repeat; }
        @media screen and (max-width: 767px) {
          .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_howKnow__Ddz8m .FormInput_row__3AZMC .FormInput_arrow__BujE4 {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
            right: -4px; } }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_btnContainer__34Lu0 {
      display: flex;
      width: calc(87% + 40px);
      padding-bottom: 20px;
      justify-content: flex-end; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_btnContainer__34Lu0 button {
        cursor: pointer;
        padding: 10px 25px;
        background: #3b88b9;
        font-size: 14px;
        color: #ffffff;
        border: none;
        outline: none;
        border-radius: 10px;
        -webkit-transition: all 250ms linear;
        transition: all 250ms linear; }
        .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_btnContainer__34Lu0 button:hover {
          background: #4795c5;
          -webkit-transition: all 250ms linear;
          transition: all 250ms linear; }
    .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_disabled__LVWlL {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .FormInput_form__13RC2 .FormInput_section__PuGN4.FormInput_disabled__LVWlL button {
        opacity: .2;
        cursor: not-allowed; }

.FormInput_newUnivItem__3rn7w {
  margin-bottom: 10px; }

.Navbar_navbar__37-oE {
  width: 100%;
  padding: 0% 5%;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  background: #57AEE3; }
  @media screen and (max-width: 767px) {
    .Navbar_navbar__37-oE {
      background: #57AEE3; } }
  .Navbar_navbar__37-oE ul {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Navbar_navbar__37-oE ul li {
      list-style: none;
      cursor: pointer; }
      .Navbar_navbar__37-oE ul li.Navbar_logo__2AWYt {
        width: 100px; }
        @media screen and (max-width: 991px) {
          .Navbar_navbar__37-oE ul li.Navbar_logo__2AWYt {
            width: 75px; } }
        @media screen and (max-width: 767px) {
          .Navbar_navbar__37-oE ul li.Navbar_logo__2AWYt {
            width: 65px; } }
        .Navbar_navbar__37-oE ul li.Navbar_logo__2AWYt img {
          max-width: 100%;
          cursor: pointer; }

.Test_test__xZ63H {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .Test_test__xZ63H {
      position: relative;
      align-items: initial;
      flex-flow: row;
      height: auto; } }
  .Test_test__xZ63H .Test_top__1OThW {
    position: absolute;
    height: 33%;
    width: 100%;
    top: 0;
    left: 0;
    background: #3b88b9;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8); }
  .Test_test__xZ63H .Test_testContainer__3H7Bp {
    position: relative;
    z-index: 2;
    width: 70%; }
    @media screen and (max-width: 991px) {
      .Test_test__xZ63H .Test_testContainer__3H7Bp {
        width: 85%; } }
    @media screen and (max-width: 767px) {
      .Test_test__xZ63H .Test_testContainer__3H7Bp {
        width: 90%;
        padding: 120px 0px 50px; } }

.Personality_selectionBox__2Mnhg {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between; }
  .Personality_selectionBox__2Mnhg a {
    text-decoration: none; }
  .Personality_selectionBox__2Mnhg.Personality_end__3Ke13 {
    justify-content: flex-end; }
  .Personality_selectionBox__2Mnhg .Personality_next__Zg1vj {
    align-self: flex-end;
    background: #3b88b9;
    cursor: pointer;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 50px 10px 15px; }
  .Personality_selectionBox__2Mnhg .Personality_prev__3qiRL {
    align-self: flex-start;
    background: #3b88b9;
    cursor: pointer;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 15px 10px 50px; }

.Personality_hide__rpKhh {
  visibility: hidden; }

.Personality_hideNone__2bR7D {
  display: none; }

.Personality_introPersonality__3lbql {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0px auto;
  border-radius: 10px;
  padding: 40px 50px 150px; }
  @media screen and (max-width: 767px) {
    .Personality_introPersonality__3lbql {
      padding: 40px 20px 40px; } }
  .Personality_introPersonality__3lbql h2 {
    margin: 0px;
    font-size: 36px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    color: #3b88b9; }
    @media screen and (max-width: 991px) {
      .Personality_introPersonality__3lbql h2 {
        font-size: 32px; } }
    @media screen and (max-width: 767px) {
      .Personality_introPersonality__3lbql h2 {
        font-size: 28px; } }
    @media screen and (max-width: 339px) {
      .Personality_introPersonality__3lbql h2 {
        font-size: 26px; } }
  .Personality_introPersonality__3lbql h4 {
    font-size: 22px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    margin: 0px; }
    @media screen and (max-width: 767px) {
      .Personality_introPersonality__3lbql h4 {
        font-size: 20px; } }
  .Personality_introPersonality__3lbql p {
    margin: 10px 0px 30px;
    font-size: 16px;
    font-family: 'SFUIDisplay Light';
    font-weight: normal; }
  .Personality_introPersonality__3lbql .Personality_icon__2zBu2 {
    position: absolute;
    bottom: 2%;
    max-width: 30%; }
    @media screen and (max-width: 767px) {
      .Personality_introPersonality__3lbql .Personality_icon__2zBu2 {
        display: none; } }
    .Personality_introPersonality__3lbql .Personality_icon__2zBu2 img {
      max-height: 200px; }
      @media screen and (max-width: 991px) {
        .Personality_introPersonality__3lbql .Personality_icon__2zBu2 img {
          max-height: 160px; } }
    .Personality_introPersonality__3lbql .Personality_icon__2zBu2.Personality_imgLeft__2i35J {
      left: 5%; }
    .Personality_introPersonality__3lbql .Personality_icon__2zBu2.Personality_imgRight__mAOBM {
      right: 5%; }

.Personality_lineBreak__M-N42 {
  display: block; }

.Personality_testContainer__18Kg7 {
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .Personality_testContainer__18Kg7 {
      padding: 20px 35px 20px; } }
  @media screen and (max-width: 479px) {
    .Personality_testContainer__18Kg7 {
      padding: 20px; } }
  .Personality_testContainer__18Kg7 .Personality_number__3Uquf {
    width: 20px;
    margin: 0px;
    font-family: 'SFUIDisplay Light'; }
  .Personality_testContainer__18Kg7 span {
    width: calc(100% - 20px); }
    .Personality_testContainer__18Kg7 span p {
      text-align: left;
      margin: 0px 0px 5px;
      font-size: 16px;
      font-family: 'SFUIDisplay Light'; }
      .Personality_testContainer__18Kg7 span p strong {
        font-size: 16px;
        font-family: 'SFUIDisplay Bold';
        font-weight: normal; }
  .Personality_testContainer__18Kg7 textarea {
    width: 100%;
    height: 250px;
    border: 1px solid #b8d4e5;
    border-radius: 5px;
    resize: vertical;
    outline: none;
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    font-family: 'SFUIDisplay Light'; }

.Personality_selectionBox__1GrKJ {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between; }
  .Personality_selectionBox__1GrKJ.Personality_intro__3JFvL a {
    background-color: #3b88b9 !important;
    color: #ffffff !important;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding: 10px 50px 10px 15px !important; }
    @media screen and (max-width: 767px) {
      .Personality_selectionBox__1GrKJ.Personality_intro__3JFvL a {
        font-size: 14px; } }
  .Personality_selectionBox__1GrKJ a {
    position: relative;
    text-decoration: none;
    border: 1px solid #3b88b9;
    overflow: hidden; }
  .Personality_selectionBox__1GrKJ.Personality_end__265YK {
    justify-content: flex-end; }
  .Personality_selectionBox__1GrKJ a.Personality_next__3sgvW {
    align-self: flex-end;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 70px 10px 15px; }
    .Personality_selectionBox__1GrKJ a.Personality_next__3sgvW span {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%; }
  .Personality_selectionBox__1GrKJ a.Personality_prev__2GpOz {
    align-self: flex-start;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 15px 10px 70px; }
    .Personality_selectionBox__1GrKJ a.Personality_prev__2GpOz span {
      position: absolute;
      content: '';
      top: 0;
      left: -1px;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: 50% 50%; }

.Personality_hide__15KYk {
  visibility: hidden; }

.Personality_hideNone__1SVRW {
  display: none; }

.English_introEnglish__3k6gD {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0px auto;
  border-radius: 10px;
  padding: 40px 50px 150px; }
  @media screen and (max-width: 767px) {
    .English_introEnglish__3k6gD {
      padding: 40px 20px 40px; } }
  .English_introEnglish__3k6gD h2 {
    margin: 0px;
    font-size: 36px;
    color: #3b88b9;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal; }
    @media screen and (max-width: 991px) {
      .English_introEnglish__3k6gD h2 {
        font-size: 32px; } }
    @media screen and (max-width: 767px) {
      .English_introEnglish__3k6gD h2 {
        font-size: 28px; } }
    @media screen and (max-width: 339px) {
      .English_introEnglish__3k6gD h2 {
        font-size: 26px; } }
  .English_introEnglish__3k6gD h4 {
    font-size: 22px;
    margin: 0px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .English_introEnglish__3k6gD h4 {
        font-size: 20px; } }
    @media screen and (max-width: 479px) {
      .English_introEnglish__3k6gD h4 .English_lineTemp__2Slap {
        display: block; } }
  .English_introEnglish__3k6gD h5 {
    font-size: 20px;
    margin: 20px 0px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .English_introEnglish__3k6gD h5 {
        font-size: 16px; } }
  .English_introEnglish__3k6gD p {
    margin: 10px 0px;
    font-size: 14px;
    font-family: 'SFUIDisplay Light';
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .English_introEnglish__3k6gD p .English_lineBreak__2t6EZ {
        display: inline-block; } }
    @media screen and (max-width: 479px) {
      .English_introEnglish__3k6gD p .English_lineBreak__2t6EZ {
        display: block; } }
  .English_introEnglish__3k6gD .English_icon__3iLzs {
    position: absolute;
    bottom: 2%;
    max-width: 30%; }
    @media screen and (max-width: 767px) {
      .English_introEnglish__3k6gD .English_icon__3iLzs {
        display: none; } }
    .English_introEnglish__3k6gD .English_icon__3iLzs img {
      max-height: 200px; }
      @media screen and (max-width: 991px) {
        .English_introEnglish__3k6gD .English_icon__3iLzs img {
          max-height: 160px; } }
    .English_introEnglish__3k6gD .English_icon__3iLzs.English_imgLeft__357vQ {
      left: 5%; }
    .English_introEnglish__3k6gD .English_icon__3iLzs.English_imgRight__Slgu- {
      right: 5%; }

.English_lineBreak__2t6EZ {
  display: block; }

.English_testContainer__2D592 {
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .English_testContainer__2D592 {
      padding: 20px 35px 20px; } }
  @media screen and (max-width: 479px) {
    .English_testContainer__2D592 {
      padding: 20px; } }
  .English_testContainer__2D592 .English_number__2GrMi {
    width: 20px;
    margin: 0px;
    font-family: 'SFUIDisplay Light'; }
  .English_testContainer__2D592 span {
    width: calc(100% - 20px); }
    .English_testContainer__2D592 span p {
      text-align: left;
      margin: 0px;
      padding-bottom: 5px;
      font-size: 16px;
      font-family: 'SFUIDisplay Light'; }
      .English_testContainer__2D592 span p strong {
        font-size: 16px;
        font-family: 'SFUIDisplay Bold'; }
    .English_testContainer__2D592 span p > span {
      display: inline-block;
      width: 60%;
      background: none !important;
      padding: 0px 10px 5px; }
      @media screen and (max-width: 479px) {
        .English_testContainer__2D592 span p > span {
          width: 100%; } }
    .English_testContainer__2D592 span p:nth-child(3) > span {
      padding-top: 10px;
      border: 1px solid #3b88b9;
      border-bottom: none;
      border-radius: 10px 10px 0px 0px; }
    .English_testContainer__2D592 span p:nth-last-child(1) > span {
      padding-bottom: 10px;
      border: 1px solid #3b88b9;
      border-top: none;
      border-radius: 0px 0px 10px 10px; }
  .English_testContainer__2D592 p {
    text-align: left;
    margin: 0px 0px 20px; }
  .English_testContainer__2D592 textarea {
    width: 100%;
    height: 250px;
    border: 1px solid #b8d4e5;
    border-radius: 5px;
    resize: vertical; }
  .English_testContainer__2D592 .English_answerForm__2iTKx {
    flex-flow: column; }
    @media screen and (max-width: 767px) {
      .English_testContainer__2D592 .English_answerForm__2iTKx {
        margin-top: 10px; } }
    .English_testContainer__2D592 .English_answerForm__2iTKx .English_answerList__K18y1 {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
      margin: 5px 0px; }
      .English_testContainer__2D592 .English_answerForm__2iTKx .English_answerList__K18y1 label {
        text-align: left;
        margin-left: 10px;
        cursor: pointer; }
      .English_testContainer__2D592 .English_answerForm__2iTKx .English_answerList__K18y1 input {
        cursor: pointer; }

.English_selectionBox__3_bzl {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between; }
  .English_selectionBox__3_bzl.English_intro__3-NoJ a {
    background-color: #3b88b9 !important;
    color: #ffffff !important;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding: 10px 50px 10px 15px !important; }
  .English_selectionBox__3_bzl a {
    position: relative;
    text-decoration: none;
    border: 1px solid #3b88b9;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .English_selectionBox__3_bzl a {
        font-size: 14px; } }
  .English_selectionBox__3_bzl.English_end__Hr_qZ {
    justify-content: flex-end; }
  .English_selectionBox__3_bzl a.English_next__20Fu8 {
    align-self: flex-end;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 70px 10px 15px; }
    .English_selectionBox__3_bzl a.English_next__20Fu8 span {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%; }
  .English_selectionBox__3_bzl a.English_prev__1DuAV {
    align-self: flex-start;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 15px 10px 70px; }
    .English_selectionBox__3_bzl a.English_prev__1DuAV span {
      position: absolute;
      content: '';
      top: 0;
      left: -1px;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: 50% 50%; }

.English_hide__3jgae {
  visibility: hidden; }

.English_hideNone__3YiV6 {
  display: none; }

.UploadProgress_modalProgress__2_jzE {
  display: flex;
  justify-content: center; }
  .UploadProgress_modalProgress__2_jzE .UploadProgress_box__1In9R {
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: #0000001a 0px 1px 5px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px; }
    .UploadProgress_modalProgress__2_jzE .UploadProgress_box__1In9R .UploadProgress_loadingBar__3QGnj {
      position: relative;
      width: 100%;
      height: 3px;
      background: #eaeaea;
      border-radius: 10px;
      overflow: hidden; }
      .UploadProgress_modalProgress__2_jzE .UploadProgress_box__1In9R .UploadProgress_loadingBar__3QGnj span {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        background: #3bb93b;
        height: 100%;
        -webkit-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out; }
    .UploadProgress_modalProgress__2_jzE .UploadProgress_box__1In9R p {
      font-family: 'SFUIDisplay Light';
      color: #181818;
      margin: 0px 0px 20px; }

.Thankyou_thankyouContainer__3oFUh {
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 40px 50px;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .Thankyou_thankyouContainer__3oFUh {
      padding: 20px 35px 20px; } }
  @media screen and (max-width: 479px) {
    .Thankyou_thankyouContainer__3oFUh {
      padding: 20px; } }
  .Thankyou_thankyouContainer__3oFUh h2 {
    font-family: 'SFUIDisplay Bold';
    font-size: 32px;
    line-height: 34px;
    color: #3B88B9;
    margin: 0px; }
    @media screen and (max-width: 767px) {
      .Thankyou_thankyouContainer__3oFUh h2 {
        font-size: 26px; } }
  .Thankyou_thankyouContainer__3oFUh p {
    font-family: 'SFUIDisplay Light';
    font-size: 18px;
    line-height: 20px;
    margin: 10px 0px 0px; }
    @media screen and (max-width: 767px) {
      .Thankyou_thankyouContainer__3oFUh p {
        font-size: 16px; } }
  .Thankyou_thankyouContainer__3oFUh .Thankyou_imgContainer__1_YH6 {
    width: 40%;
    margin: 50px auto 0px; }
    @media screen and (max-width: 767px) {
      .Thankyou_thankyouContainer__3oFUh .Thankyou_imgContainer__1_YH6 {
        margin: 50px auto 0px;
        width: 60%; } }
    @media screen and (max-width: 479px) {
      .Thankyou_thankyouContainer__3oFUh .Thankyou_imgContainer__1_YH6 {
        width: 80%; } }
    .Thankyou_thankyouContainer__3oFUh .Thankyou_imgContainer__1_YH6 img {
      max-width: 100%; }
  .Thankyou_thankyouContainer__3oFUh .Thankyou_linebreak__3ZglU {
    display: block; }

.Timer_timeContainer__1N3_g {
  position: fixed;
  top: 27px;
  right: 5%;
  z-index: 100; }
  @media screen and (max-width: 991px) {
    .Timer_timeContainer__1N3_g {
      top: 23px;
      right: 5%; } }
  .Timer_timeContainer__1N3_g p {
    margin: 0px;
    font-size: 16px;
    font-family: "SFUIDisplay Semibold";
    color: #ffffff; }
    @media screen and (max-width: 767px) {
      .Timer_timeContainer__1N3_g p {
        font-size: 14px; } }

.NotFound_container__17cR9 {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9EE6FC;
  background: #9FCBFF;
  background: linear-gradient(147deg, #9EE6FC 0%, #9FCBFF 100%); }
  .NotFound_container__17cR9 h1 {
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    font-size: 20px;
    color: #ffffff; }
    .NotFound_container__17cR9 h1 span {
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      font-size: 60px; }

