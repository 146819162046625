.listItem
{
  border: 1px solid #3b88b9;
  border-radius: 7px;
  margin-top: 20px;
  list-style: none;
  padding: 10px 20px;
  .listContainer
  {
    // cursor: pointer;;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    .position
    {
      width: 55%;
      @media screen and (max-width: 767px) {
        width: 50%;
      }
      p
      {
        text-align: left;
        margin: 0px;
        color: #909090;
        font-size: 16px;
        font-family: 'SFUIDisplay Semibold';
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    .location
    {
      width: 20%;
      margin-left: 20px;
      margin-right: auto;
      @media screen and (max-width: 767px) {
        margin-left: 10px;
        width: 24%;
      }
      p
      {
        text-align: left;
        margin: 0px;
        color: #909090;
        font-family: 'SFUIDisplay Semibold';
        font-size: 16px;
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    .details
    {
      width: 15%;
      display: flex;
      justify-content: center;
      a
      {
        cursor: pointer;
        display: block;
        margin: 0px;
        color: #3b88b9;
        padding-bottom: 2px;
        text-decoration: none;
        font-size: 16px;
        font-family: 'SFUIDisplay Light';
        transition: transform 200ms linear;
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
        &:after
        {
          content: "";
          display: block;
          width: 100%; height: 1px;
          background: #8ec6e5;
          transition: transform 200ms linear;
        }
        &:hover
        {
          transform: translateY(-2px);
          transition: transform 200ms linear;
          &:after
          {
            transform: translateY(2px);
            transition: transform 200ms linear;
          }
        }
      }
    }
  }
  .detailInfo
  {
    text-align: left !important;
    &.hide
    {
      max-height: 0px;
      padding: 0px;
      overflow: hidden;
      opacity: 0;
      // transition: all 500ms linear;
    }
    &.show
    {
      height: auto;
      max-height: 10000px;
      padding: 0px 0px 20px;
      transition: all 500ms linear;
      opacity: 1;
    }
    .title, .descript
    {
      color: #2b2b2b;
      margin: 0px;
      text-align: left;
    }

    .title
    {
      border-top: 1px solid #3b88b9;
      margin-top: 10px;
      padding-top: 25px;
      font-family: 'SFUIDisplay Bold';
      font-size: 16px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    // .descript
    // {
    //   margin-top: 5px;
    //   font-family: 'SFUIDisplay Light';
    //   font-size: 16px;
    //   @media screen and (max-width: 767px) {
    //     font-size: 14px;
    //   }
    // }
    // .listQualification
    // {
    //   padding: 20px 20px 0px;
    //   li
    //   {
    //     font-size: 16px;
    //     font-family: 'SFUIDisplay Light';
    //     list-style: disc;
    //     text-align: left;
    //     margin: 5px 0px;
    //     @media screen and (max-width: 767px) {
    //       font-size: 14px;
    //     }
    //   }
    // }

    .jobDesc, .require
    {
      h4
      {
        color: #2b2b2b;
        text-align: left;
        margin: 5px 0px 10px;
        font-family: 'SFUIDisplay Light';
        font-weight: normal;
        font-size: 16px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      p
      {
        font-size: 16px;
        line-height: 18px;
        font-family: 'SFUIDisplay Light';
        text-align: left;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      ul
      {
        padding: 10px 20px 0px;
        li
        {
          font-size: 16px;
          font-family: 'SFUIDisplay Light';
          list-style: disc;
          text-align: left;
          margin: 5px 0px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .require
    {
      h4
      {
        margin-top: 50px;
      }
    }
    .btnSelection
    {
      padding: 30px 0px 20px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        flex-flow: column;
      }
      button
      {
        margin: 0 3%;
        padding: 10px 20px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: #ffffff;
        font-size: 14px;
        font-family: 'SFUIDisplay Semibold';
        cursor: pointer;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          margin-top: 10px;
        }
        &.interest
        {
          background: #3b88b9;
          &:hover
          {
            background: #4f96c2;
          }
        }
        &.refer
        {
          background: #2b2b2b;
          &:hover
          {
            background: #444444;
          }
        }
      }
    }
    .referContainer
    {
      overflow: hidden;
      padding: 20px 0px 20px;

      .referInfo
      {
        font-size: 16px;
        font-family: 'SFUIDisplay Light';
        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin: 20px 0px 10px;
        }
        span
        {
          font-family: 'SFUIDisplay Semibold';
        }
      }
      form
      {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        @media screen and (max-width: 767px) {
          padding: 0px 10px;
        }
        input
        {
          border: 1px solid #3b88b9;
          border-radius: 10px;
          outline: none;
          padding: 5px 10px;
          margin: 0px 10px;
          font-size: 14px;
          font-family: 'SFUIDisplay Light';
          &::placeholder {
            color: #909090;
          }

          @media screen and (max-width: 767px) {
            margin: 10px 0px 0px;
            width: 100%;
            font-size: 12px;
          }
        }
        .btnContainer
        {
          width: 100%;
          display: flex;
          justify-content: center;
          button
          {
            cursor: pointer;
            font-size: 14px;
            font-family: 'SFUIDisplay Light';
            background: #3b88b9;
            outline: none;
            border-radius: 10px;
            border: 1px solid transparent;
            color: #ffffff;
            padding: 5px 15px;
            margin-top: 10px;
            @media screen and (max-width: 767px) {
              width: 100%;
              font-size: 12px;
            }
          }
        }
        
      }
    }
  }
}