.form
{
  display: flex;
  flex-flow: row wrap;
  .info
  {
    color: red;
    width: 100%;
    text-align: left;
    font-size: 14px;
    @media screen and (max-width: 767px){
      font-size: 12px;
      display: block;
      margin-bottom: 50px !important;
    }
  }
  .section
  {
    width: 100%;
    margin-top: 20px;
    &.buttonInfo
    {
      @media screen and (max-width: 767px){
        flex-flow: row wrap;
      }
    }
    .row
    {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      &>span
      {
        // position: relative;
        display: flex;
        flex-flow: column;
        margin-right: 20px;
        width: 29%;
        @media screen and (max-width: 991px){
          margin-right: 15px;
        }
        @media screen and (max-width: 767px){
          width: 100%;
          margin: 5px 0px;
        }
        input
        {
          width: 100%;
          border: 1px solid #3b88b9;
          padding: 5px 15px;
          font-size: 14px;
          font-family: 'SFUIDisplay Light';
          border-radius: 10px;
          outline: none;
        }
      }
    }
    .selectionRadio
    {
      display: flex;
      flex-flow: row wrap;
      padding-left: 0px;
      margin: 5px 0px;
    }
    h4
    {
      width: 100%;
      text-align: left;
      margin: 5px 0px;
      font-family: 'SFUIDisplay Bold';
      &.semiBold
      {
        font-family: 'SFUIDisplay Semibold';
      }
    }
    p
    {
      text-align: left;
      margin: 10px 0px;
      &.provide
      {
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
        @media screen and (max-width: 479px) {
          font-size: 14px;
        }
      }
    }
    
    &.email, &.phone, &.salary
    {
      .row>span
      {
        width: calc(58% + 20px);
        @media screen and (max-width: 767px){
          width: 100%;
          margin: 5px 0px;
        }
      }
    }

    &.university, &.experience, &.highSchool
    {
      overflow: hidden;
      margin: 0px;
      max-height: 0px;
      transition: 300ms;
    }

    &.show
    {
      max-height: none;
      margin-top: 20px;
      transition: 300ms;
      @media screen and (max-width: 767px) {
        
      }
    }

    &.start
    {
      .datePicker
      {
        position: relative;
        z-index: 10;
        width: calc(58% + 20px);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &.row
        {
          &>span
          {
            width: 100%;
            margin: 0px;
            &>div>div
            {
              width: 100%;
            }
          }
        }
        
        &>div:nth-child(1)
        {
          width: 100%;
          &>div
          {
            width: 100%;
          }
        }
        &>div:nth-child(2)
        {
          // right: 0;
          // left: auto !important;
        }
        input
        {
          cursor: pointer;
          width: 100%;
        }
      }
    }

    &.university, &.experience, &.newUniv
    {
      .row
      {
        @media screen and (max-width:767px) {
          position: relative;
          padding: 10px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          margin-bottom: 40px;
        }
      }
      .row:not(:nth-of-type(1))
      {
        margin-top: 15px;
        @media screen and (max-width:767px) {
          margin-top: 60px;
        }
      }
      .btnContainer
      {
        display: block;
        width: 22px;  
        height: 22px;
        margin: 0px 5px !important;

        @media screen and (max-width:767px) {
          height: 30px;
          margin: 5px 5px !important;
          position: absolute;
          top: calc(100% + 5px);
          left: 0px;
        }
        
        button
        {
          cursor: pointer;
          padding: 50%;
          background-color: #3b88b9;
          background-size: 50%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          border: none;
          border-radius: 7px;
          outline: none;
          transform: translateY(-2px);
          transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          box-shadow: 0 5px 2px 0px #2b2b2b;
          box-shadow: 0 5px 2px 0 rgba(49, 53, 59, 0.50);
          &:hover
          {
            background-color: #4e98c7;
          }
          &:active
          {
            box-shadow: 0 2px #2b2b2b;
            transform: translateY(0px);
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
      }
    }

    &.howKnow
    {
      .row
      {
        position: relative;
        width: calc(58% + 20px);
        @media screen and (max-width: 767px) {
          width: auto;
        }
        
        p
        {
          position: relative;
          cursor: pointer;
          width: 100%;
          padding: 5px 15px;
          margin: 0px;
          text-align: left;
          font-size: 14px;
          border: 1px solid #3b88b9;
          border-radius: 10px;
          overflow: hidden;
        }
        .arrow
        {
          position: absolute;
          top: 0;
          right: 0px;
          content: "";
          display: block;
          width: 35px;
          height: 100%;
          background-color: #3b88b9;
          background-size: 30%;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          @media screen and (max-width: 767px) {
            transform: rotate(90deg);
            right: -4px;
          }
        }
      }
    }

    &.btnContainer
    {
      display: flex;
      width: calc(87% + 40px);
      padding-bottom: 20px;
      justify-content: flex-end;
      button
      {
        cursor: pointer;
        padding: 10px 25px;
        background: #3b88b9;
        font-size: 14px;
        color: #ffffff;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: all 250ms linear;
        &:hover
        {
          background: #4795c5;
          transition: all 250ms linear;
        }
      }
    }
    &.disabled
    {
      user-select: none;
      button
      {
        opacity: .2;
        cursor: not-allowed;
      }
    }
  }
}

.newUnivItem
{
  margin-bottom: 10px;
}