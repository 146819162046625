.spinnerContainer
{
  position: fixed;
  top: 0; left: 0;
  height: 100%; width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // background: #57AEE3;
  background: #9FCBFF;
  background: linear-gradient(147deg, #9EE6FC 0%, #9FCBFF 100%);
  z-index: 100;
  .imgContainer
  {
    width: 120px;
    margin: 0px auto 5px;
    img
    {
      width: 100%;
    }
  }
  .loadingBar
  {
    position: relative;
    width: 150px;
    background: #ffffff;
    height: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    span
    {
      position: absolute;
      content: "";
      height: 100%;
      top: 0; 
      left: 0;
      background: #3e8fc2;
      // background: #C5F7B3;
      // background: linear-gradient(147deg, #C5F7B3 0%, #B1EBEB 100%);
      transition: width 1s ease-in-out;
    }
  }
}