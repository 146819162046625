.modalContainer
{
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0,0,0,.1);
  .modalPlc
  {
    background: #f9f9f9;
    position: relative;
    z-index: 2;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.12);
    @media screen and (max-width: 767px) {
      width: 80%;
      padding: 20px;
    }
    @media screen and (max-width: 479px) {
      padding: 40px 20px 20px;
    }
    .btnClose
    {
      position: absolute;
      // background: #ED5742;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      cursor: pointer;
      &:hover
      {
        // background: rgb(247, 117, 100);
      }
      &::before, &::after
      {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        margin: auto;
        content: "";
        width: 70%;
        height: 2px;
        background: #ED5742;
      }
      &::before
      {
        transform: rotate(45deg);
      }
      &::after
      {
        transform: rotate(-45deg);
      }
    }
    p
    {
      font-family: "SFUIDisplay Light";
      font-size: 14px;
      margin: 0px;
      color: #333333;
      span
      {
        display: block;
        margin: 10px 0px 20px;
        font-family: "SFUIDisplay SemiBold";
        font-size: 16px;
      }
    }
    button
    {
      cursor: pointer;
      outline: none;
      border: 1px solid transparent;
      border-radius: 5px;
      padding: 10px 15px;
      margin: 0px 10px;
      color: #ffffff;
      box-shadow: 0 1px 6px 0 #31353b1f;
      box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);
      &.new
      {
        background: #469bfd;
        background: #ffffff;
        color: #333333;
        @media screen and (max-width: 767px) {
          width: 200px;
        }
        @media screen and (max-width: 479px) {
          width: 150px;
        }
        &:hover
        {
          background: #368bec;
          background: #f5f5f5;
        }
      }
      &.edit
      {
        background: #47c969;
        background: #469bfd;
        @media screen and (max-width: 767px) {
          display: block;
          margin: 10px auto 0px;
          width: 200px;
        }
        @media screen and (max-width: 479px) {
          width: 150px;
        }
        &:hover
        {
          background: #1ea542;
          background: #368bec;
        }
      }
    }
  }
}