.timeContainer
{
  position: fixed;
  top: 27px;right: 5%;
  @media screen and (max-width: 991px) {
    top: 23px;right: 5%;
  }
  z-index: 100;
  p
  {
    margin: 0px;
    font-size: 16px;
    font-family: "SFUIDisplay Semibold";
    color: #ffffff;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}