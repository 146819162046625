.selectionBox
{
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  a
  {
    text-decoration: none;
  }
  &.end
  {
    justify-content: flex-end;
  }
  .next
  {
    align-self: flex-end;
    background: #3b88b9;
    cursor: pointer;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 50px 10px 15px;
  }
  .prev
  {
    align-self: flex-start;
    background: #3b88b9;
    cursor: pointer;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 15px 10px 50px;
  }
}

.hide
{
  visibility: hidden;
}
.hideNone
{
  display: none;
}