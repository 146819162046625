.test
{
  position: absolute;
  overflow: hidden;
  height: 100%; width: 100%;
  top: 0;left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    position: relative;
    align-items: initial;
    flex-flow: row;
    height: auto;
  }
  .top
  {
    position: absolute;
    height: 33%; width: 100%;
    top: 0;left: 0;
    background: #3b88b9;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .8)
  }

  .testContainer
  {
    position: relative;
    z-index: 2;
    width: 70%;
    @media screen and (max-width: 991px) {
      width: 85%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
      padding: 120px 0px 50px;
    }
  }
}