.info
{
  font-size: 16px;
  font-family: "SFUIDisplay Light";
}

.keyword
{
  font-size: 16px;
  line-height: 18px;
  font-family: 'SFUIDisplay Light';
  @media screen and(max-width: 767px)
  {
    font-size: 14px;
    line-height: 16px;
  }
  span
  {
    font-size: 16px;
    line-height: 18px;
    font-family: 'SFUIDisplay Semibold';
    @media screen and(max-width: 767px)
    {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.moreVacancy
{
  cursor: pointer;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: #3b88b9;
  font-family: 'SFUIDisplay Light';
  font-size: 12px;
  color: #ffffff;
  border: 1px solid transparent;
  outline: none;
  &:hover
  {
    background: #4f9ac9;
  }
  &:active
  {
    transform: translateY(1px);
    // transition: 200ms;
    // border-radius: 50%;
    // text-transform: none;
  }
}

.vacancyTitle
{
  margin: 0px 0px 2%;
  @media screen and (max-width: 991px) {
    margin: 0px auto 20px;
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    margin: 0px auto 20px;
    width: 90%;
  }
  h2
  {
    color: #2b2b2b;
    margin: 0px 0px 5px;
    font-size: 32px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    line-height: 34px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 30px;
    }
    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 24px;
    }
  }
  p
  {
    color: #2b2b2b;
    margin: 0px;
    font-size: 24px;
    font-family: 'SFUIDisplay Light';
    line-height: 26px;
    letter-spacing: .3px;
    @media screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 22px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
.vacancySearch
{
  width: 60%;
  margin: 0px auto 30px;
  @media screen and (max-width: 1199px) {
    width: 70%;
  }
  @media screen and (max-width: 991px) {
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
  form
  {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    input
    {
      border: 1px solid #3b88b9;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 14px;
      font-family: 'SFUIDisplay Light';
      outline: none;
      &.search
      {
        width: 55%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .locationContainer
    {
      position: relative;
      cursor: pointer;
      width: 20%;
      border: 1px solid #3b88b9;
      border-radius: 10px;
      margin: 0px auto 0px 20px;
      font-family: 'SFUIDisplay Light';
      outline: none;
      // overflow: hidden;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 10px auto;
      }
      select
      {
        cursor: pointer;
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        padding: 5px 10px;
        margin: 0px;
        font-family: 'SFUIDisplay Light';
        font-size: 14px;
        appearance: none;
        background: none;
        outline: none;
        border: none;
        option
        {
          font-family: 'SFUIDisplay Light';
        }
      }
      div.arrow
      {
        position: absolute;
        z-index: 1;
        content: "";
        display: block;
        width: 25px;height: 100%;
        top: 0px;
        right: -1px;
        outline: none;
        border: none;
        background-color: #3b88b9;
        border-radius: 0px 10px 10px 0px;
        span.arrowContainer
        {
          content: "";
          display: block;
          width: 100%; height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 45%;
          transform: rotate(90deg);
        }
      }
      ul
      {
        width: 100%;
        position: absolute;
        top: 32px;left: 0px;
        padding: 0px 0px;
        margin: 0px;
        background-color: #cccccc;
        border-radius: 0px 0px 10px 10px;
        li
        {
          padding: 5px 10px;
          list-style: none;
          color: #ffffff;
        }
      }
    }
    button
    {
      cursor: pointer;
      font-size: 14px;
      font-family: 'SFUIDisplay Light';
      width: 15%;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid #3b88b9;
      background: #3b88b9;
      color: #ffffff;
      outline: none;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.vacancyList
{
  width: 60%;
  margin: auto;
  padding: 0px;
  @media screen and (max-width: 1199px) {
    width: 70%;
  }
  @media screen and (max-width: 991px) {
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
  &.title
  {
    background: #3b88b9;
    border-radius: 10px;
    .listItem
    {
      border: none;
      .listContainer
      {
        cursor: default;
        .position, .location
        {
          p
          {
            color: #ffffff;
          }
        }
      }
    }
  }
  .listItem
  {
    border: 1px solid #3b88b9;
    border-radius: 7px;
    margin-top: 20px;
    list-style: none;
    padding: 10px 20px;
    .listContainer
    {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      .position
      {
        width: 55%;
        @media screen and (max-width: 767px) {
          width: 50%;
        }
        p
        {
          text-align: left;
          margin: 0px;
          color: #909090;
          font-family: 'SFUIDisplay Bold';
          font-size: 16px;
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      .location
      {
        width: 20%;
        margin-left: 20px;
        margin-right: auto;
        @media screen and (max-width: 767px) {
          margin-left: 10px;
          width: 24%;
        }
        p
        {
          text-align: left;
          margin: 0px;
          color: #909090;
          font-family: 'SFUIDisplay Bold';
          font-size: 16px;
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      .details
      {
        width: 15%;
        a
        {
          cursor: pointer;
          margin: 0px;
          color: #3b88b9;
          padding-bottom: 2px;
          border-bottom: 1px solid #3b88b9;
          font-size: 16px;
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}