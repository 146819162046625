.vccItem
{
  cursor: pointer;
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  
  .vccWrapper
  {
    .vccContent
    {
      h2
      {
        margin: 0px 0px 5px;
        font-family: 'SFUIDisplay Bold';
        font-size: 26px;
        line-height: 28px;
        color: #2b2b2b;
        @media screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      p
      {
        margin: 0px;
        font-size: 20px;
        font-family: 'SFUIDisplay light';
        line-height: 22px;
        color: #2b2b2b;
        @media screen and (max-width: 991px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
  &.vcc1, &.vcc2, &.vcc4, &.vcc5
  {
    width: 49.5%;
    padding: 2% 0px 22%;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 5% 5% 45%;
      margin-top: 10px;
    }
  }
  &.vcc3,&.vcc6
  {
    width: 100%;
    margin: 1% 0px;
    background-size: 100%;
    padding: 0px;
    @media screen and (max-width: 1199px) {
      background-size: cover;
    }
    @media screen and (max-width: 767px)
    {
      position: relative;
      padding: 5% 0px 45%;
      margin: 10px 0px 0px;
    }
    &::after
    {
      @media screen and (max-width: 767px) {
        content: "";
        display: block;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        height: 100%; width: 100%;
        background: rgb(86,174,225);
        background: linear-gradient(180deg, rgba(86,174,225,1) 32%, rgba(213,235,248,0) 80%, rgba(255,255,255,0) 100%); 
      } 
    }
    .vccWrapper
    {
      padding: 12% 0px;
      width: 49.5%;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0px;
        position: relative;
        z-index: 2;
      }
      .vccContent
      {
        h2,p
        {
          color: #ffffff;
        }
      }
    }
  }
  &.vcc3
  {
    @media screen and (max-width: 1199px) {
      background-position: 0% 100%;
    }
    @media screen and (max-width: 767px) {
      background-position: 0% 0%;
    }
    .vccWrapper
    {
      margin-left: auto;
    }
  }
  &.vcc6
  {
    @media screen and (max-width: 1199px) {
      background-position: 100% 100%;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}