// .inputElement
// {
//   position: relative;
//   display: flex;
//   flex-flow: column;
//   margin-right: 20px;
//   width: 29%;
//   .invalid
//   {
//     border-color: red;
//   }
//   input
//   {
//     width: 100%;
//     border: 1px solid #3b88b9;
//     padding: 5px 15px;
//     font-size: 14px;
//     font-family: 'SFUIDisplay Light';
//     border-radius: 10px;
//     outline: none;
//   }
//   .feedback
//   {
//     p
//     {
//       font-size: 12px;
//       color: red;
//       margin: 5px 0px 0px;
//     }
//   }
// }

.inputElement
{
  li.radioInput
  {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 5px 0px;
    .check
    {
      position: absolute;
      top: 2px; left: 0px;
      width: 25px; height: 25px;
      margin: auto 0px;
      border-radius: 100%;
      border: 2px solid #7cabc9;
      &::before
      {
        position: absolute;
        content: '';
        top: 3px; left: 3px;
        width: 15px; height: 15px;
        margin: auto;
        background: transparent;
        border-radius: 100%;
      }
    }
    label
    {
      width: 100px;
      padding-left: 15px;
      z-index: 2;
      cursor: pointer;
      color: #909090;
      transition: all 250ms linear;
    }
    input
    {
      position: absolute;
      width: 20px;
      margin: 0px 7px 0px 0px;
      visibility: hidden;
      &:checked
      {
        color: red;
      }
      &:checked ~ label
      {
        color: #2b2b2b;
      }
      &:checked ~ .check
      {
        border-color: #3b88b9;
      }
      &:checked ~ .check::before
      {
        background: #3b88b9;
      }
    }
    
    &:hover
    {
      .check
      {
        border-color: #3b88b9;
        transition: border 250ms linear;
        &::before
        {
          transition: background 250ms linear;
        }
      }
      label
      {
        color: #2b2b2b;
        transition: all 250ms linear;
      }
    }
  }

  ul.knowBy
  {
    display: none;
    width: 200px;
    position: absolute;
    left: calc(100% + 40px);
    top: 0px;
    margin: 0px;
    padding-left: 0px;
    background: #ffffff;
    border: 1px solid #63aad8;
    border-radius: 0px 0px 5px 5px;
    @media screen and (max-width: 767px) {
      margin-top: 0px;
      width: 100%;
      position: relative;
      left: unset;
      right: unset;
      border-top: 0px;
    }
    span.blueArrow
    {
      content: "";
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50% 50%;
      position: absolute;
      top: 5px;left: -18px;
      z-index: 9;
      @media screen and (max-width: 767px) {
       display: none;
      }
    }
    &.show
    {
      display: block;
    }
    li
    {
      cursor: pointer;
      list-style: none;
      padding: 10px 15px;
      margin: 0px;
      text-align: left;
      color: #195c87;
      font-size: 14px;
      &:hover
      {
        background: #63aad8;
        color: #ffffff;
        transition: all 150ms linear;
      }
    }
  }
}


.invalid
{
  border-color: #f50e0e !important;
}
.valid
{
  border-color: #3bb93b !important;
}

.feedback
{
  p
  {
    font-size: 12px;
    color: #f50e0e;
    margin: 5px 0px 0px !important;
  }
}