.introEnglish
{
  position: relative;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, .2);
  width: 100%;
  margin: 0px auto;
  border-radius: 10px;
  padding: 40px 50px 150px;
  @media screen and (max-width: 767px) {
    padding: 40px 20px 40px;
}
  h2
  {
    margin: 0px;
    font-size: 36px;
    color: #3b88b9;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    @media screen and (max-width: 991px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
    @media screen and (max-width: 339px) {
      font-size: 26px;
    }
  }
  h4
  {
    font-size: 22px;
    margin: 0px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
    .lineTemp
    {
      @media screen and (max-width: 479px) {
        display: block;
      }
    }
  }
  h5
  {
    font-size: 20px;
    margin: 20px 0px;
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  p
  {
    margin: 10px 0px;
    font-size: 14px;
    font-family: 'SFUIDisplay Light';
    font-weight: normal;
    @media screen and (max-width: 767px) {
      // padding: 40px 20px 40px;
    }
    .lineBreak
    {
      @media screen and (max-width: 767px) {
        display: inline-block;
      }
      @media screen and (max-width: 479px) {
        display: block;
      }
    }
  }
  .icon
  {
    position: absolute;
    bottom: 2%;
    max-width: 30%;
    @media screen and (max-width: 767px) {
      display: none;
    }
    img
    {
      max-height: 200px;
      @media screen and (max-width: 991px) {
        max-height: 160px;
      }
    }
    &.imgLeft
    {
      left: 5%;
    }
    &.imgRight
    {
      right: 5%;
    }
  }
}

.lineBreak
{
  display: block;
}

.testContainer
{
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, .2);
  border-radius: 10px;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    padding: 20px 35px 20px;
  }
  @media screen and (max-width: 479px) {
    padding: 20px;
  }
  .number
  {
    width: 20px;
    margin: 0px;
    font-family: 'SFUIDisplay Light';
  }
  span
  {
    width: calc(100% - 20px);
    p
    {
      text-align: left;
      // margin: 0px 0px 5px;
      margin: 0px;
      padding-bottom: 5px;
      font-size: 16px;
      font-family: 'SFUIDisplay Light';
      
      strong
      {
        font-size: 16px;
        font-family: 'SFUIDisplay Bold';
      }
    }

    p > span
    {
      display: inline-block;
      width: 60%;
      background: none !important;
      padding: 0px 10px 5px;
      @media screen and (max-width: 479px) 
      {
        width: 100%;
      }
    }
    
    p:nth-child(3) > span
    {
      padding-top: 10px;
      border : 1px solid #3b88b9;
      border-bottom: none;
      border-radius: 10px 10px 0px 0px;
    }

    p:nth-last-child(1) > span
    {
      padding-bottom: 10px;
      border : 1px solid #3b88b9;
      border-top: none;
      border-radius: 0px 0px 10px 10px;
    }
  }
  // span > p:nth-of-type(3) > span
  // {
  //   background: yellow !important;
  // }
  p
  {
    text-align: left;
    margin: 0px 0px 20px;
  }


  textarea
  {
    width: 100%;
    height: 250px;
    border: 1px solid #b8d4e5;
    border-radius: 5px;
    resize: vertical;
  }

  .answerForm
  {
    flex-flow: column;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
    .answerList
    {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
      margin: 5px 0px;
      label
      {
        text-align: left;
        margin-left: 10px;
        cursor: pointer;
      }
      input
      {
        cursor: pointer;
      }
    }
  }
}

.selectionBox
{
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  &.intro
  {
    a
    {
      background-color: #3b88b9 !important;
      color: #ffffff !important;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 95% 50%;
      padding: 10px 50px 10px 15px !important;
    }
  }
  a
  {
    position: relative;
    text-decoration: none;
    border: 1px solid #3b88b9;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  &.end
  {
    justify-content: flex-end;
  }
  a.next
  {
    align-self: flex-end;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 70px 10px 15px;
    span
    {
      position: absolute;
      content: '';
      top: 0;right: 0;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  a.prev
  {
    align-self: flex-start;
    background-color: #ffffff;
    cursor: pointer;
    color: #3b88b9;
    border-radius: 3px;
    padding: 10px 15px 10px 70px;
    span
    {
      position: absolute;
      content: '';
      top: 0;left: -1px;
      width: 35%;
      height: 100%;
      background-color: #3b88b9;
      background-size: 20px;
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
}

.hide
{
  visibility: hidden;
}
.hideNone
{
  display: none;
}