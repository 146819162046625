.container
{
  position: fixed;
  z-index: -1;
  top: 0; left: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #B0CAE5;
  // background: #B4BEE1;
  // background: linear-gradient(147deg, rgba(180,190,225,1) 0%, rgba(185,153,222,1) 100%);
  
  background: #9EE6FC;
  background: #9FCBFF;
  background: linear-gradient(147deg, #9EE6FC 0%, #9FCBFF 100%);
  
  h1
  {
    font-family: 'SFUIDisplay Bold';
    font-weight: normal;
    font-size: 20px;
    color: #ffffff;
    span
    {
      font-family: 'SFUIDisplay Bold';
      font-weight: normal;
      font-size: 60px;
    }
  }
}