@font-face {
  font-family: 'SFUIDisplay Light';
  src: url('./assets/font/sf-ui-display-light.eot') format('eot'), url('./assets/font/sf-ui-display-light.otf') format('opentype'), url('./assets/font/sf-ui-display-light.ttf') format('truetype');
}
@font-face {
  font-family: 'SFUIDisplay Semibold';
  src: url('./assets/font/sf-ui-display-semibold.eot') format('eot'), url('./assets/font/sf-ui-display-semibold.otf') format('opentype'), url('./assets/font/sf-ui-display-semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'SFUIDisplay Bold';
  src: url('./assets/font/sf-ui-display-bold.eot') format('eot'), url('./assets/font/sf-ui-display-bold.otf') format('opentype'), url('./assets/font/sf-ui-display-bold.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before
{ 
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
