.thankyouContainer
{
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 40px 50px;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 20px 35px 20px;
  }
  @media screen and (max-width: 479px) {
    padding: 20px;
  }
  h2
  {
    font-family: 'SFUIDisplay Bold';
    font-size: 32px;
    line-height: 34px;
    color: #3B88B9;
    margin: 0px;
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  p
  {
    font-family: 'SFUIDisplay Light';
    font-size: 18px;
    line-height: 20px;
    margin: 10px 0px 0px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  .imgContainer
  {
    width: 40%;
    margin: 50px auto 0px;
    @media screen and (max-width: 767px) {
      margin: 50px auto 0px;
      width: 60%;
    }
    @media screen and (max-width: 479px) {
      width: 80%;
    }
    img
    {
      max-width: 100%;
    }
  }
  .linebreak
  {
    display: block;
  }
}