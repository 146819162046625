.modalProgress
{
  display: flex;
  justify-content: center;
  .box
  {
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: #0000001a 0px 1px 5px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    .loadingBar
    {
      position: relative;
      width: 100%;
      height: 3px;
      background: #eaeaea;
      border-radius: 10px;
      overflow: hidden;
      span
      {
        position: absolute;
        top: 0px; left: 0px;
        display: block;
        background: #3bb93b;
        height: 100%;
        transition: width 1s ease-in-out;
      }
    }
    p
    {
      font-family: 'SFUIDisplay Light';
      color: #181818;
      margin: 0px 0px 20px;
    }
  }
}